import * as Apollo from '@apollo/client';
import {gql} from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _FieldSet: { input: any; output: any; }
};

export enum App_Preference {
  PhpClient = 'PHP_CLIENT',
  ReactClient = 'REACT_CLIENT'
}

export type AccessCheckResult = {
  __typename?: 'AccessCheckResult';
  anonymousAccess?: Maybe<Scalars['Boolean']['output']>;
  payStubExists: Scalars['Boolean']['output'];
  payStubHasUser?: Maybe<Scalars['Boolean']['output']>;
  signedInAccess: Scalars['Boolean']['output'];
  userSignedIn: Scalars['Boolean']['output'];
  userSignedInWithoutAccessButHasEmployees?: Maybe<Scalars['Boolean']['output']>;
};

export enum ActionObject {
  Employee = 'EMPLOYEE',
  Employer = 'EMPLOYER'
}

export type AnonDataCheckResult = {
  __typename?: 'AnonDataCheckResult';
  numPayStubsToTransfer: Scalars['Int']['output'];
};

export type AnonUserContext = {
  __typename?: 'AnonUserContext';
  provinceCode: Scalars['String']['output'];
};

export enum AuthenticationFailureKey {
  AnonUserIdMissing = 'ANON_USER_ID_MISSING',
  NoAuthTokenFound = 'NO_AUTH_TOKEN_FOUND',
  UserNotFound = 'USER_NOT_FOUND'
}

export type CalculateDeductionsResult = {
  __typename?: 'CalculateDeductionsResult';
  deductions: Array<Deduction>;
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type CalculatedDeductions = {
  __typename?: 'CalculatedDeductions';
  cpp?: Maybe<Scalars['String']['output']>;
  cpp2?: Maybe<Scalars['String']['output']>;
  ei?: Maybe<Scalars['String']['output']>;
  fedTax?: Maybe<Scalars['String']['output']>;
  incomeTax?: Maybe<Scalars['String']['output']>;
  provTax?: Maybe<Scalars['String']['output']>;
};

export type CalculatedNeededResult = {
  __typename?: 'CalculatedNeededResult';
  hasStaleCraCalculatedDeductions?: Maybe<Scalars['Boolean']['output']>;
  hasUserProvidedCoreDeductions?: Maybe<Scalars['Boolean']['output']>;
};

export type ClaimCode = {
  __typename?: 'ClaimCode';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type CreateNextPayStubResult = {
  __typename?: 'CreateNextPayStubResult';
  message?: Maybe<Scalars['String']['output']>;
  payStubWithDeductions?: Maybe<PayStubWithDeductions>;
  successful: Scalars['Boolean']['output'];
};

export type CurrentIncomeItem = {
  __typename?: 'CurrentIncomeItem';
  amount: Scalars['String']['output'];
  incomeLabel: Scalars['String']['output'];
  incomeName: Scalars['String']['output'];
};

export type Deduction = {
  __typename?: 'Deduction';
  amount: Scalars['String']['output'];
  currentPeriodId?: Maybe<Scalars['Int']['output']>;
  deductionTypeId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  payStubId: Scalars['Int']['output'];
  priorPeriodAmount: Scalars['String']['output'];
  priorPeriodId?: Maybe<Scalars['Int']['output']>;
  ytdAmount: Scalars['String']['output'];
};

export type DeductionItemInput = {
  amount: Scalars['String']['input'];
  currentPeriodId?: InputMaybe<Scalars['Int']['input']>;
  deductionTypeId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  payStubId: Scalars['Int']['input'];
  priorPeriodAmount: Scalars['String']['input'];
  priorPeriodId?: InputMaybe<Scalars['Int']['input']>;
  ytdAmount: Scalars['String']['input'];
};

export type DeductionType = {
  __typename?: 'DeductionType';
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  taxTreatment: TaxTreatment;
};

export type DeductionTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  taxTreatmentCode: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type DeleteLogoResult = {
  __typename?: 'DeleteLogoResult';
  employerId: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type DentalCode = {
  __typename?: 'DentalCode';
  code: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  accrueVacation: Scalars['Boolean']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyUserId?: Maybe<Scalars['String']['output']>;
  cppExempt: Scalars['Boolean']['output'];
    dateOfBirth?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  dentalOfferedCode: Scalars['Int']['output'];
  detailedName: Scalars['String']['output'];
  eiExempt: Scalars['Boolean']['output'];
  employerEiRatio: Scalars['String']['output'];
  employmentProvince: Scalars['String']['output'];
  federalClaimCode: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  payFrequency: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  provincialClaimCode: Scalars['String']['output'];
  sin?: Maybe<Scalars['String']['output']>;
  vacationPayPercent: Scalars['String']['output'];
};

export type EmployeeAndMostRecent = {
  __typename?: 'EmployeeAndMostRecent';
  employee: Employee;
  mostRecentEmployee: Employee;
};

export type EmployeeInput = {
  accrueVacation: Scalars['Boolean']['input'];
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  applyToExistingStubs?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyUserId?: InputMaybe<Scalars['String']['input']>;
  cppExempt: Scalars['Boolean']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  dentalOfferedCode: Scalars['Int']['input'];
  eiExempt: Scalars['Boolean']['input'];
  employerEiRatio: Scalars['String']['input'];
  employmentProvince: Scalars['String']['input'];
  federalClaimCode: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  payFrequency: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  provincialClaimCode: Scalars['String']['input'];
  sin?: InputMaybe<Scalars['String']['input']>;
  vacationPayPercent: Scalars['String']['input'];
};

export type EmployeeWithHasPayStubsStatus = {
  __typename?: 'EmployeeWithHasPayStubsStatus';
  employee: Employee;
  hasPayStubs: Scalars['Boolean']['output'];
};

export type EmployeeWithStatus = {
  __typename?: 'EmployeeWithStatus';
  employee: Employee;
  id: Scalars['String']['output'];
  recommendedActions?: Maybe<Array<RecommendedEmployeeAction>>;
};

export type Employer = {
  __typename?: 'Employer';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  detailedName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  logoHeight?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  logoWidth?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
};

export type EmployerInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  applyToExistingStubs?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export type FinalizeResult = {
  __typename?: 'FinalizeResult';
  finalized: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  paymentRequired: Scalars['Boolean']['output'];
};

export type GenerateReportResult = {
  __typename?: 'GenerateReportResult';
  generated: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  messages: Array<Scalars['String']['output']>;
  userAlert?: Maybe<Scalars['String']['output']>;
  validations: Array<ReportValidation>;
};

export type GetEmployeesResult = {
  __typename?: 'GetEmployeesResult';
  employees: Array<Employee>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type IncomeItem = {
  __typename?: 'IncomeItem';
  amount: Scalars['String']['output'];
  currentPeriodItemId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  includeInVacationAccrual: Scalars['Boolean']['output'];
  incomeTypeId: Scalars['Int']['output'];
  payStubId: Scalars['Int']['output'];
  priorPeriodAmount: Scalars['String']['output'];
  priorPeriodItemId: Scalars['String']['output'];
  rate?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  ytdAmount: Scalars['String']['output'];
};

export type IncomeItemInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  currentPeriodItemId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  includeInVacationAccrual: Scalars['Boolean']['input'];
  incomeTypeId: Scalars['Int']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  priorPeriodAmount: Scalars['String']['input'];
  priorPeriodItemId?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
};

export type IncomeType = {
  __typename?: 'IncomeType';
  code?: Maybe<Scalars['String']['output']>;
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isBenefit: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  rateLabel: Scalars['String']['output'];
  t4OtherIncomeType?: Maybe<T4OtherIncomeType>;
  t4OtherIncomeTypeCode?: Maybe<Scalars['String']['output']>;
  taxTreatment: TaxTreatment;
  unitsLabel: Scalars['String']['output'];
};

export type IncomeTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  rateLabel: Scalars['String']['input'];
  t4OtherIncomeTypeCode?: InputMaybe<Scalars['String']['input']>;
  taxTreatmentCode: Scalars['String']['input'];
  unitsLabel: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type InsurableEarnings = {
  __typename?: 'InsurableEarnings';
  payPeriods: Array<PayPeriod>;
  userMessage?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  calculateDeductions: CalculateDeductionsResult;
  calculateVacationSummary: Result;
  createEmptyPayStub: Scalars['Int']['output'];
  createNextPayStub: CreateNextPayStubResult;
  deleteDeduction: Result;
  deleteEmployee: Result;
  deleteIncomeItem: Result;
  deleteIncomeType: Result;
  deleteLogo: DeleteLogoResult;
  deletePayStub: Result;
  deleteVacationSummary: Result;
  executeAdminAction: Result;
  finalizePayStub: FinalizeResult;
  resetPayStub: Result;
  restoreEmployee: Result;
  saveCalculatedDeductions: Array<Deduction>;
  saveDeduction: Deduction;
  saveDeductionType: DeductionType;
  saveEmployee: SaveEmployeeResult;
  saveEmployeeToPayStub: PayStubWithDeductions;
  saveEmployer: Employer;
  saveEmployerToPayStub: PayStubWithDeductions;
  saveIncomeItem: SaveIncomeItemResult;
  saveIncomeType: IncomeType;
  saveLogoToEmployer: SaveLogoResult;
  savePayDate: SavePayDateResult;
  savePayPeriod: SavePayPeriodResult;
  saveUserProfile: SaveUserProfileResult;
  saveUserSetting: UserSetting;
  saveVacationSummary: SaveVacationSummaryResult;
  sendEmailVerification: Result;
  sendSystemNotice: SystemNoticeResult;
  sendTrustPilotInvitation: TrustPilotInvitationResult;
  transferAnonUserData: TransferResult;
  updatePayStubBasedOnPreviousPayStub: PayStubWithDeductions;
  updateUserAuthIds: Result;
};


export type MutationCalculateDeductionsArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type MutationCalculateVacationSummaryArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type MutationCreateEmptyPayStubArgs = {
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateNextPayStubArgs = {
  nextPayDate: Scalars['String']['input'];
  previousPayStubId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteDeductionArgs = {
  anonUserId: Scalars['String']['input'];
  currentPeriodId?: InputMaybe<Scalars['Int']['input']>;
  priorPeriodId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteEmployeeArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteIncomeItemArgs = {
  anonUserId: Scalars['String']['input'];
  currentPeriodItemId: Scalars['String']['input'];
  priorPeriodItemId: Scalars['String']['input'];
};


export type MutationDeleteIncomeTypeArgs = {
  anonUserId: Scalars['String']['input'];
  incomeTypeId: Scalars['Int']['input'];
};


export type MutationDeleteLogoArgs = {
  anonUserId: Scalars['String']['input'];
  applyToExistingPayStubs: Scalars['Boolean']['input'];
  employerId: Scalars['Int']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeletePayStubArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteVacationSummaryArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type MutationExecuteAdminActionArgs = {
  token: Scalars['String']['input'];
};


export type MutationFinalizePayStubArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationResetPayStubArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type MutationRestoreEmployeeArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveCalculatedDeductionsArgs = {
  anonUserId: Scalars['String']['input'];
  deductionItems: Array<DeductionItemInput>;
  payStubId: Scalars['Int']['input'];
};


export type MutationSaveDeductionArgs = {
  anonUserId: Scalars['String']['input'];
  deduction: DeductionItemInput;
  payStubId: Scalars['Int']['input'];
};


export type MutationSaveDeductionTypeArgs = {
  anonUserId: Scalars['String']['input'];
  deductionTypeInput: DeductionTypeInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveEmployeeArgs = {
  anonUserId: Scalars['String']['input'];
  employeeInput: EmployeeInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveEmployeeToPayStubArgs = {
  anonUserId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveEmployerArgs = {
  anonUserId: Scalars['String']['input'];
  employerInput: EmployerInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveEmployerToPayStubArgs = {
  anonUserId: Scalars['String']['input'];
  employerId: Scalars['Int']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveIncomeItemArgs = {
  anonUserId: Scalars['String']['input'];
  incomeItemInput: IncomeItemInput;
};


export type MutationSaveIncomeTypeArgs = {
  anonUserId: Scalars['String']['input'];
  incomeTypeInput: IncomeTypeInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveLogoToEmployerArgs = {
  anonUserId: Scalars['String']['input'];
  applyToExistingPayStubs: Scalars['Boolean']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  saveLogoInput: SaveLogoInput;
};


export type MutationSavePayDateArgs = {
  anonUserId: Scalars['String']['input'];
  savePayDateInput: SavePayDateInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSavePayPeriodArgs = {
  anonUserId: Scalars['String']['input'];
  savePayPeriodInput: SavePayPeriodInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveUserProfileArgs = {
  anonUserId: Scalars['String']['input'];
  userProfileInput: UserProfileInput;
};


export type MutationSaveUserSettingArgs = {
  type: UserSettingType;
  value: Scalars['String']['input'];
};


export type MutationSaveVacationSummaryArgs = {
  anonUserId: Scalars['String']['input'];
  vacationSummaryInput: VacationSummaryInput;
};


export type MutationSendSystemNoticeArgs = {
  anonUserId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


export type MutationSendTrustPilotInvitationArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationTransferAnonUserDataArgs = {
  anonUserId: Scalars['String']['input'];
};


export type MutationUpdatePayStubBasedOnPreviousPayStubArgs = {
  anonUserId: Scalars['String']['input'];
  nextPayDate: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
  previousPayStubId: Scalars['Int']['input'];
};

export type NextPayDateResult = {
  __typename?: 'NextPayDateResult';
  message?: Maybe<Scalars['String']['output']>;
  nextPayDate?: Maybe<Scalars['String']['output']>;
};

export type OtherMonies = {
  __typename?: 'OtherMonies';
  amount: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  otherMoniesCode: OtherMoniesCode;
  otherMoniesNumber: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export enum OtherMoniesCode {
  B05BonusHoliday = 'B05_Bonus_Holiday',
  B06BonusProductionIncentive = 'B06_Bonus_Production_Incentive',
  B07BonusEvent = 'B07_Bonus_Event',
  B08BonusStayingContractCompleteEndOfSeason = 'B08_Bonus_Staying_Contract_complete_End_of_season',
  B09BonusSeparationOrRetirement = 'B09_Bonus_Separation_or_retirement',
  B10BonusClosure = 'B10_Bonus_Closure',
  B11BonusOther = 'B11_Bonus_Other',
  E00SeverancePay = 'E00_Severance_pay',
  G00Gratuities = 'G00_Gratuities',
  H00Honorariums = 'H00_Honorariums',
  I00SickLeaveCredits = 'I00_Sick_leave_credits',
  J00RetroactivePayAdjustment = 'J00_Retroactive_pay_adjustment',
  O00Other = 'O00_Other',
  Q00ProfitSharing = 'Q00_Profit_sharing',
  R00RetiringAllowanceRetirementLeaveCredits = 'R00_Retiring_allowance_Retirement_leave_credits',
  S00SettlementPay = 'S00_Settlement_pay',
  T00PayoutsOfBankedOvertime = 'T00_Payouts_of_banked_overtime',
  U12SubMaternityParentalCompassionateCareFamilyCaregiver = 'U12_SUB_Maternity_Parental_Compassionate_Care_Family_Caregiver',
  U13SubLayoff = 'U13_SUB_Layoff',
  U14SubIllness = 'U14_SUB_Illness',
  U15SubTraining = 'U15_SUB_Training',
  Y00PayInLieuOfNotice = 'Y00_Pay_in_lieu_of_notice'
}

export type OtherMoniesInput = {
  amount: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  otherMoniesCode: OtherMoniesCode;
  otherMoniesNumber: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type PayFrequency = {
  __typename?: 'PayFrequency';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PayPeriod = {
  __typename?: 'PayPeriod';
  amount: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export type PayPeriodInput = {
  amount: Scalars['String']['input'];
  id: Scalars['String']['input'];
  number: Scalars['Int']['input'];
};

export type PayStub = {
  __typename?: 'PayStub';
  currentPeriodIncomeId?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  employerCpp?: Maybe<Scalars['String']['output']>;
  employerCpp2?: Maybe<Scalars['String']['output']>;
  employerEi?: Maybe<Scalars['String']['output']>;
  employerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  periodEnd: Scalars['String']['output'];
  periodStart: Scalars['String']['output'];
  priorPeriodsIncomeId?: Maybe<Scalars['String']['output']>;
};

export type PayStubBasic = {
  __typename?: 'PayStubBasic';
  finalized: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  payDate: Scalars['String']['output'];
  payPeriod: Scalars['String']['output'];
};

export type PayStubWithCalculatedDeductions = {
  __typename?: 'PayStubWithCalculatedDeductions';
  calculatedDeductions: CalculatedDeductions;
  payStubWithCalculatedIncome: PayStubWithCalculatedIncome;
};

export type PayStubWithCalculatedIncome = {
  __typename?: 'PayStubWithCalculatedIncome';
  employeeAndMostRecent: EmployeeAndMostRecent;
  employer: Employer;
  employerCpp?: Maybe<Scalars['String']['output']>;
  employerCpp2?: Maybe<Scalars['String']['output']>;
  employerEi?: Maybe<Scalars['String']['output']>;
  incomeItems: Array<CurrentIncomeItem>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  payDate: Scalars['String']['output'];
  payPeriodEnd: Scalars['String']['output'];
  payPeriodStart: Scalars['String']['output'];
  payStubId: Scalars['String']['output'];
};

export type PayStubWithDeductions = {
  __typename?: 'PayStubWithDeductions';
  deductions: Array<Deduction>;
  finalized: Scalars['Boolean']['output'];
  payDate: Scalars['String']['output'];
  payStub: PayStub;
  previousPayStubId?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<TotalAmounts>;
};

export type Province = {
  __typename?: 'Province';
  firstYearVacationPayRate: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  basicValidateAllPayStubs: ValidateEmployeesResult;
  checkPayStubAccess: AccessCheckResult;
  dentalCodes: Array<DentalCode>;
  generatePayStub: GenerateReportResult;
  generateReport: GenerateReportResult;
  generateRoeReport: GenerateReportResult;
  getAnonUserContext: AnonUserContext;
  getClaimCodes: Array<ClaimCode>;
  getDeductionTypes: Array<DeductionType>;
  getDeductions: Array<Deduction>;
  getEmployee: EmployeeWithHasPayStubsStatus;
  getEmployeeMostRecent: EmployeeWithHasPayStubsStatus;
  getEmployees?: Maybe<GetEmployeesResult>;
  getEmployeesWithStatus: Array<EmployeeWithStatus>;
  getEmployer: Employer;
  getEmployers: Array<Employer>;
  getIncomeItems: Array<IncomeItem>;
  getNextPayDate: NextPayDateResult;
  getNumberOfAnonymousPayStubsToTransfer: AnonDataCheckResult;
  getPayFrequencies: Array<PayFrequency>;
  getPayStubsForEmployee: Array<PayStubBasic>;
  getPreviousPayStubsForEmployee: Array<PayStubBasic>;
  getProvinces: Array<Province>;
  getReportsAvailable: Array<ReportAvailable>;
  getRoeInitialValuesForEmployee: RoeInitialValuesResult;
  getSignedInUser: User;
  getSubscribers: Array<UserActivation>;
  getUserActivations: Array<UserActivation>;
  getUserSetting: UserSetting;
  getUsers: Array<User>;
  getVacationSummary: VacationSummary;
  incomeTypeTaxTreatments: Array<TaxTreatment>;
  incomeTypes: Array<IncomeType>;
  payStub: PayStubWithDeductions;
  payStubCalculateNeeded: CalculatedNeededResult;
  payStubs: Array<PayStubWithCalculatedDeductions>;
  t4OtherIncomeTypes: Array<T4OtherIncomeType>;
  validateEmployees: ValidateEmployeesResult;
  validateEmployers: ValidateEmployeesResult;
  validatePayStubDeductionCalculations: ValidateEmployeesResult;
  validatePayStubEmployers: ValidateEmployeesResult;
  validatePayStubs: ValidateEmployeesResult;
};


export type QueryCheckPayStubAccessArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryGeneratePayStubArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryGenerateReportArgs = {
  params: ReportParamsInput;
};


export type QueryGenerateRoeReportArgs = {
  params: RoeReportParameterInput;
};


export type QueryGetAnonUserContextArgs = {
  anonUserId: Scalars['String']['input'];
};


export type QueryGetDeductionTypesArgs = {
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDeductionsArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryGetEmployeeArgs = {
  anonUserId: Scalars['String']['input'];
  currentPayStubId?: InputMaybe<Scalars['Int']['input']>;
  employeeId: Scalars['Int']['input'];
};


export type QueryGetEmployeeMostRecentArgs = {
  anonUserId: Scalars['String']['input'];
  employeeId: Scalars['Int']['input'];
};


export type QueryGetEmployeesArgs = {
  anonUserId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetEmployeesWithStatusArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryGetEmployerArgs = {
  anonUserId: Scalars['String']['input'];
  employerId: Scalars['Int']['input'];
};


export type QueryGetEmployersArgs = {
  anonUserId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetIncomeItemsArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryGetNextPayDateArgs = {
  payStubId: Scalars['Int']['input'];
};


export type QueryGetNumberOfAnonymousPayStubsToTransferArgs = {
  anonUserId: Scalars['String']['input'];
};


export type QueryGetPayStubsForEmployeeArgs = {
  anonUserId: Scalars['String']['input'];
  employeeId: Scalars['Int']['input'];
};


export type QueryGetPreviousPayStubsForEmployeeArgs = {
  anonUserId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  maxPayDate: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetRoeInitialValuesForEmployeeArgs = {
  employeeId: Scalars['String']['input'];
};


export type QueryGetSignedInUserArgs = {
  anonUserId: Scalars['String']['input'];
};


export type QueryGetUserSettingArgs = {
  defaultValue: Scalars['String']['input'];
  type: UserSettingType;
};


export type QueryGetUsersArgs = {
  userSearchParams: UserSearchParams;
};


export type QueryGetVacationSummaryArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryIncomeTypesArgs = {
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPayStubArgs = {
  anonUserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type QueryPayStubCalculateNeededArgs = {
  anonUserId: Scalars['String']['input'];
  payStubId: Scalars['Int']['input'];
};


export type QueryPayStubsArgs = {
  userId: Scalars['Int']['input'];
};

export type ReasonForIssuing = {
  contactAreaCode: Scalars['String']['input'];
  contactExtension?: InputMaybe<Scalars['String']['input']>;
  contactFirstName: Scalars['String']['input'];
  contactLastName: Scalars['String']['input'];
  contactPhone: Scalars['String']['input'];
  reasonCode: RoeSeparationCode;
};

export type RecommendedAction = {
  __typename?: 'RecommendedAction';
  actionObject: ActionObject;
  description: Scalars['String']['output'];
  objectIdToAction: Scalars['String']['output'];
};

export type RecommendedEmployeeAction = {
  __typename?: 'RecommendedEmployeeAction';
  description: Scalars['String']['output'];
  employeeId: Scalars['Int']['output'];
};

export type ReportAvailable = {
  __typename?: 'ReportAvailable';
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  externalLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  infoLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pagePath?: Maybe<ReportPagePath>;
  params: Array<ReportParam>;
  videoGuideLink?: Maybe<Scalars['String']['output']>;
};

export enum ReportPagePath {
  Payrollhistory = 'payrollhistory',
  Roereport = 'roereport'
}

export type ReportParam = {
  __typename?: 'ReportParam';
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  selectOptions?: Maybe<Array<SelectOption>>;
  type: ReportParamType;
};

export enum ReportParamType {
  Array = 'ARRAY',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateRange = 'DATE_RANGE',
  MoneyAmount = 'MONEY_AMOUNT',
  String = 'STRING',
  Year = 'YEAR'
}

export type ReportParameterInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: ReportParamType;
  value: Array<Scalars['String']['input']>;
};

export type ReportParamsInput = {
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  params: Array<ReportParameterInput>;
};

export type ReportValidation = {
  __typename?: 'ReportValidation';
  employeeId?: Maybe<Scalars['Int']['output']>;
  employeeName?: Maybe<Scalars['String']['output']>;
  messages: Array<Scalars['String']['output']>;
};

export type Result = {
  __typename?: 'Result';
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type RoeExpectedRecall = {
  expectedRecallCode?: InputMaybe<RoeExpectedRecallCode>;
  expectedRecallDate?: InputMaybe<Scalars['String']['input']>;
};

export enum RoeExpectedRecallCode {
  NotReturning = 'NOT_RETURNING',
  Unknown = 'UNKNOWN',
  YesReturning = 'YES_RETURNING'
}

export type RoeInitialValues = {
  __typename?: 'RoeInitialValues';
  contactAreaCode: Scalars['String']['output'];
  contactFirstName: Scalars['String']['output'];
  contactLastName: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  employeeAddress?: Maybe<Scalars['String']['output']>;
  employeeCity?: Maybe<Scalars['String']['output']>;
  employeeFirstName: Scalars['String']['output'];
  employeeId: Scalars['String']['output'];
  employeeLastName: Scalars['String']['output'];
  employeeMiddleInitial?: Maybe<Scalars['String']['output']>;
  employeeOccupation?: Maybe<Scalars['String']['output']>;
  employeePostalCode?: Maybe<Scalars['String']['output']>;
  employeeProvinceAndCountry?: Maybe<Scalars['String']['output']>;
  finalPayPeriodEndDate: Scalars['String']['output'];
  firstDayWorked?: Maybe<Scalars['String']['output']>;
  insurableEarnings: InsurableEarnings;
  lastDayForWhichPaid?: Maybe<Scalars['String']['output']>;
  otherMonies?: Maybe<Array<OtherMonies>>;
  payPeriodType: RoePayPeriodType;
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>;
  specialPayments?: Maybe<Array<SpecialPayments>>;
  statHolidayPay?: Maybe<Array<StatHolidayPay>>;
  submitDraft: Scalars['Boolean']['output'];
  totalInsurableHours: Scalars['Int']['output'];
  vacationPay?: Maybe<VacationPay>;
};

export type RoeInitialValuesResult = {
  __typename?: 'RoeInitialValuesResult';
  hasFinalizedStubs: Scalars['Boolean']['output'];
  initialValues?: Maybe<RoeInitialValues>;
};

export enum RoePayPeriodType {
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  MonthlyNonStandard = 'MONTHLY_NON_STANDARD',
  SemiMonthly = 'SEMI_MONTHLY',
  SemiMonthlyNonStandard = 'SEMI_MONTHLY_NON_STANDARD',
  ThirteenPayPeriodsPerYear = 'THIRTEEN_PAY_PERIODS_PER_YEAR',
  Weekly = 'WEEKLY'
}

export type RoeReportParameterInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  employeeAddress: Scalars['String']['input'];
  employeeCity: Scalars['String']['input'];
  employeeFirstName: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  employeeLastName: Scalars['String']['input'];
  employeeMiddleInitial?: InputMaybe<Scalars['String']['input']>;
  employeeOccupation?: InputMaybe<Scalars['String']['input']>;
  employeePostalCode: Scalars['String']['input'];
  employeeProvinceAndCountry?: InputMaybe<Scalars['String']['input']>;
  expectedRecall: RoeExpectedRecall;
  finalPayPeriodEndDate: Scalars['String']['input'];
  firstDayWorked: Scalars['String']['input'];
  insurableEarnings: Array<PayPeriodInput>;
  lastDayForWhichPaid: Scalars['String']['input'];
  otherMonies?: InputMaybe<Array<OtherMoniesInput>>;
  payPeriodType: RoePayPeriodType;
  payrollAccountNumber: Scalars['String']['input'];
  reasonForIssuing: ReasonForIssuing;
  socialInsuranceNumber: Scalars['String']['input'];
  specialPayments?: InputMaybe<Array<SpecialPaymentsInput>>;
  statHolidayPay?: InputMaybe<Array<StatHolidayPayInput>>;
  submitDraft: Scalars['Boolean']['input'];
  totalInsurableHours: Scalars['Int']['input'];
  vacationPay?: InputMaybe<VacationPayInput>;
};

export enum RoeSeparationCode {
  A00ShortageOfWorkEndOfContractOrSeason = 'A00_Shortage_of_work_End_of_contract_or_season',
  A01EmployerBankruptcyOrReceivership = 'A01_Employer_bankruptcy_or_receivership',
  B00StrikeOrLockout = 'B00_Strike_or_lockout',
  D00IllnessOrInjury = 'D00_Illness_or_injury',
  E00Quit = 'E00_Quit',
  E02QuitFollowSpouse = 'E02_Quit_Follow_spouse',
  E03QuitReturnToSchool = 'E03_Quit_Return_to_school',
  E04QuitHealthReasons = 'E04_Quit_Health_reasons',
  E05QuitVoluntaryRetirement = 'E05_Quit_Voluntary_retirement',
  E06QuitTakeAnotherJob = 'E06_Quit_Take_another_job',
  E09QuitEmployerRelocation = 'E09_Quit_Employer_relocation',
  E10QuitCareForADependant = 'E10_Quit_Care_for_a_dependant',
  E11QuitToBecomeSelfEmployed = 'E11_Quit_To_become_self_employed',
  F00Maternity = 'F00_Maternity',
  G00MandatoryRetirement = 'G00_Mandatory_retirement',
  G07RetirementApprovedWorkforceReduction = 'G07_Retirement_Approved_workforce_reduction',
  H00WorkSharing = 'H00_Work_Sharing',
  J00ApprenticeTraining = 'J00_Apprentice_training',
  K00Other = 'K00_Other',
  K12OtherChangeOfPayrollFrequency = 'K12_Other_Change_of_payroll_frequency',
  K13OtherChangeOfOwnership = 'K13_Other_Change_of_ownership',
  K14OtherRequestedByEmploymentInsurance = 'K14_Other_Requested_by_Employment_Insurance',
  K15OtherCanadianForcesQueenSRegulationsOrders = 'K15_Other_Canadian_Forces_Queen_s_Regulations_Orders',
  K16OtherAtTheEmployeeSRequest = 'K16_Other_At_the_employee_s_request',
  K17OtherChangeOfServiceProvider = 'K17_Other_Change_of_Service_Provider',
  M00Dismissal = 'M00_Dismissal',
  M08DismissalTerminatedWithinProbationaryPeriod = 'M08_Dismissal_Terminated_within_probationary_period',
  N00LeaveOfAbsence = 'N00_Leave_of_absence',
  P00Parental = 'P00_Parental',
  Z00CompassionateCareFamilyCaregiver = 'Z00_Compassionate_Care_Family_Caregiver'
}

export type SaveEmployeeResult = {
  __typename?: 'SaveEmployeeResult';
  employee?: Maybe<Employee>;
  messages: Array<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type SaveIncomeItemResult = {
  __typename?: 'SaveIncomeItemResult';
  incomeItem?: Maybe<IncomeItem>;
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type SaveLogoInput = {
  employerId: Scalars['Int']['input'];
  logoPublicId: Scalars['String']['input'];
};

export type SaveLogoResult = {
  __typename?: 'SaveLogoResult';
  employerId: Scalars['Int']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type SavePayDateInput = {
  payDate: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
};

export type SavePayDateResult = {
  __typename?: 'SavePayDateResult';
  message?: Maybe<Scalars['String']['output']>;
  payDate: Scalars['String']['output'];
  payStubId: Scalars['Int']['output'];
  successful: Scalars['Boolean']['output'];
};

export type SavePayPeriodInput = {
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  periodEnd: Scalars['String']['input'];
  periodStart: Scalars['String']['input'];
};

export type SavePayPeriodResult = {
  __typename?: 'SavePayPeriodResult';
  message?: Maybe<Scalars['String']['output']>;
  payPeriodEnd: Scalars['String']['output'];
  payPeriodStart: Scalars['String']['output'];
  payStubId: Scalars['Int']['output'];
  successful: Scalars['Boolean']['output'];
};

export type SaveUserProfileResult = {
  __typename?: 'SaveUserProfileResult';
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type SaveVacationSummaryResult = {
  __typename?: 'SaveVacationSummaryResult';
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
  vacationSummary?: Maybe<VacationSummary>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  defaultSelected: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SpecialPayments = {
  __typename?: 'SpecialPayments';
  amount?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  specialPaymentsCode: SpecialPaymentsCode;
  specialPaymentsPeriod?: Maybe<SpecialPaymentsPeriod>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export enum SpecialPaymentsCode {
  Mat01PaidMaternityParentalCompassionateCareFamilyEiInsurable = 'MAT01_Paid_Maternity_Parental_Compassionate_Care_Family_EI_Insurable',
  Psl01PaidSickLeaveEiInsurable = 'PSL01_Paid_sick_leave_EI_Insurable',
  Wli01WageLossIndemnityNotEiInsurable = 'WLI01_Wage_loss_indemnity_Not_EI_insurable',
  Wli02WageLossIndemnityEiInsurable = 'WLI02_Wage_loss_indemnity_EI_Insurable'
}

export type SpecialPaymentsInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  specialPaymentsCode: SpecialPaymentsCode;
  specialPaymentsPeriod?: InputMaybe<SpecialPaymentsPeriod>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export enum SpecialPaymentsPeriod {
  D = 'D',
  W = 'W'
}

export type StatHolidayPay = {
  __typename?: 'StatHolidayPay';
  amount: Scalars['String']['output'];
  holidayDate: Scalars['String']['output'];
  statHolidayNumber: Scalars['Int']['output'];
};

export type StatHolidayPayInput = {
  amount: Scalars['String']['input'];
  holidayDate?: InputMaybe<Scalars['String']['input']>;
  statHolidayNumber: Scalars['Int']['input'];
};

export type SystemNoticeResult = {
  __typename?: 'SystemNoticeResult';
  successful: Scalars['Boolean']['output'];
};

export type T4OtherIncomeType = {
  __typename?: 'T4OtherIncomeType';
  boxNumber?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  t4OtherIncomeTypeCode: Scalars['String']['output'];
};

export type TaxTreatment = {
  __typename?: 'TaxTreatment';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type TotalAmounts = {
  __typename?: 'TotalAmounts';
  totalCurrentBenefits: Scalars['String']['output'];
  totalCurrentDeductions: Scalars['String']['output'];
  totalCurrentGross: Scalars['String']['output'];
  totalCurrentIncome: Scalars['String']['output'];
  totalCurrentNet: Scalars['String']['output'];
  totalCurrentNetExclBenefits: Scalars['String']['output'];
  totalYtdBenefits: Scalars['String']['output'];
  totalYtdDeductions: Scalars['String']['output'];
  totalYtdGross: Scalars['String']['output'];
  totalYtdIncome: Scalars['String']['output'];
  totalYtdNet: Scalars['String']['output'];
};

export type TransferResult = {
  __typename?: 'TransferResult';
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type TrustPilotInvitationResult = {
  __typename?: 'TrustPilotInvitationResult';
  error?: Maybe<Scalars['String']['output']>;
  sent: Scalars['Boolean']['output'];
};

export type UpdateEmployerResult = {
  __typename?: 'UpdateEmployerResult';
  message?: Maybe<Scalars['String']['output']>;
  successful: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  auth0Id?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  provinceCode?: Maybe<Scalars['String']['output']>;
};

export type UserActivation = {
  __typename?: 'UserActivation';
  activationStatus?: Maybe<Scalars['String']['output']>;
  firstEmployee?: Maybe<Scalars['String']['output']>;
  firstEmployer?: Maybe<Scalars['String']['output']>;
  numEmployees?: Maybe<Scalars['Int']['output']>;
  numEmployers?: Maybe<Scalars['Int']['output']>;
  numPayStubs?: Maybe<Scalars['Int']['output']>;
  numPayStubsCompleted?: Maybe<Scalars['Int']['output']>;
  numPayStubsFinalized?: Maybe<Scalars['Int']['output']>;
  numPayStubsWithYtd?: Maybe<Scalars['Int']['output']>;
  subscriptionEmployeeCount?: Maybe<Scalars['Int']['output']>;
  subscriptionStatus?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type UserProfileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
  provinceCode: Scalars['String']['input'];
  termsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSearchParams = {
  auth0Id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  daysSinceLastUpdated?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  type: UserSettingType;
  value: Scalars['String']['output'];
};

export enum UserSettingType {
  AppPreference = 'APP_PREFERENCE'
}

export type VacationPay = {
  __typename?: 'VacationPay';
  amount?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  vacationPayCode: VacationPayCode;
};

export enum VacationPayCode {
  AnniversaryPaidOnSpecificDateEachYear = 'ANNIVERSARY_PAID_ON_SPECIFIC_DATE_EACH_YEAR',
  IncludedWithEachPay = 'INCLUDED_WITH_EACH_PAY',
  PaidBecauseNoLongerWorking = 'PAID_BECAUSE_NO_LONGER_WORKING',
  PaidForVacationLeavePeriod = 'PAID_FOR_VACATION_LEAVE_PERIOD'
}

export type VacationPayInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  vacationPayCode: VacationPayCode;
};

export type VacationSummary = {
  __typename?: 'VacationSummary';
  currentPeriodEarned: Scalars['String']['output'];
  currentPeriodUsedPaid: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  payStubId: Scalars['Int']['output'];
  previousPeriodEarned: Scalars['String']['output'];
  previousPeriodUsedPaid: Scalars['String']['output'];
  vacationAccrued: Scalars['String']['output'];
  ytdEarned: Scalars['String']['output'];
  ytdUsedPaid: Scalars['String']['output'];
};

export type VacationSummaryInput = {
  currentPeriodEarned: Scalars['String']['input'];
  currentPeriodUsedPaid: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  payStubId: Scalars['Int']['input'];
  previousPeriodEarned: Scalars['String']['input'];
  previousPeriodUsedPaid: Scalars['String']['input'];
  vacationAccrued: Scalars['String']['input'];
  ytdEarned: Scalars['String']['input'];
  ytdUsedPaid: Scalars['String']['input'];
};

export type ValidateEmployeesResult = {
  __typename?: 'ValidateEmployeesResult';
  started: Scalars['String']['output'];
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String']['output'];
};

export type CalculateDeductionsMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type CalculateDeductionsMutation = { __typename?: 'Mutation', calculateDeductions: { __typename?: 'CalculateDeductionsResult', successful: boolean, message?: string | null, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }> } };

export type CalculateVacationSummaryMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type CalculateVacationSummaryMutation = { __typename?: 'Mutation', calculateVacationSummary: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type CheckPayStubAccessQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type CheckPayStubAccessQuery = { __typename?: 'Query', checkPayStubAccess: { __typename?: 'AccessCheckResult', signedInAccess: boolean, anonymousAccess?: boolean | null, userSignedIn: boolean, userSignedInWithoutAccessButHasEmployees?: boolean | null, payStubHasUser?: boolean | null, payStubExists: boolean } };

export type CreateEmptyPayStubMutationVariables = Exact<{
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateEmptyPayStubMutation = { __typename?: 'Mutation', createEmptyPayStub: number };

export type CreateNextPayStubMutationVariables = Exact<{
  previousPayStubId: Scalars['Int']['input'];
  nextPayDate: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateNextPayStubMutation = { __typename?: 'Mutation', createNextPayStub: { __typename?: 'CreateNextPayStubResult', successful: boolean, message?: string | null, payStubWithDeductions?: { __typename?: 'PayStubWithDeductions', finalized: boolean, payDate: string, previousPayStubId?: number | null, payStub: { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null }, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }>, totalAmounts?: { __typename?: 'TotalAmounts', totalCurrentIncome: string, totalCurrentBenefits: string, totalCurrentGross: string, totalYtdIncome: string, totalYtdBenefits: string, totalYtdGross: string, totalCurrentDeductions: string, totalYtdDeductions: string, totalCurrentNet: string, totalYtdNet: string, totalCurrentNetExclBenefits: string } | null } | null } };

export type DeleteDeductionMutationVariables = Exact<{
  currentPeriodId?: InputMaybe<Scalars['Int']['input']>;
  priorPeriodId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type DeleteDeductionMutation = { __typename?: 'Mutation', deleteDeduction: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DeleteEmployeeMutationVariables = Exact<{
  employeeId: Scalars['Int']['input'];
}>;


export type DeleteEmployeeMutation = { __typename?: 'Mutation', deleteEmployee: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DeleteIncomeItemMutationVariables = Exact<{
  currentPeriodId: Scalars['String']['input'];
  priorPeriodId: Scalars['String']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type DeleteIncomeItemMutation = { __typename?: 'Mutation', deleteIncomeItem: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DeleteIncomeTypeMutationVariables = Exact<{
  incomeTypeId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type DeleteIncomeTypeMutation = { __typename?: 'Mutation', deleteIncomeType: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DeleteLogoMutationVariables = Exact<{
  employerId: Scalars['Int']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  applyToExistingPayStubs: Scalars['Boolean']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type DeleteLogoMutation = { __typename?: 'Mutation', deleteLogo: { __typename?: 'DeleteLogoResult', successful: boolean, message?: string | null, employerId: number } };

export type DeletePayStubMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type DeletePayStubMutation = { __typename?: 'Mutation', deletePayStub: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DeleteVacationSummaryMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type DeleteVacationSummaryMutation = { __typename?: 'Mutation', deleteVacationSummary: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type DentalCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type DentalCodesQuery = { __typename?: 'Query', dentalCodes: Array<{ __typename?: 'DentalCode', code: number, label: string }> };

export type ExecuteAdminActionMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ExecuteAdminActionMutation = {
    __typename?: 'Mutation',
    executeAdminAction: { __typename?: 'Result', successful: boolean, message?: string | null }
};

export type FinalizePayStubMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type FinalizePayStubMutation = {
    __typename?: 'Mutation',
    finalizePayStub: {
        __typename?: 'FinalizeResult',
        finalized: boolean,
        paymentRequired: boolean,
        message?: string | null
    }
};

export type GeneratePayStubQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GeneratePayStubQuery = { __typename?: 'Query', generatePayStub: { __typename?: 'GenerateReportResult', generated: boolean, messages: Array<string>, link?: string | null } };

export type GenerateReportQueryVariables = Exact<{
  params: ReportParamsInput;
}>;


export type GenerateReportQuery = { __typename?: 'Query', generateReport: { __typename?: 'GenerateReportResult', generated: boolean, link?: string | null, messages: Array<string>, userAlert?: string | null, validations: Array<{ __typename?: 'ReportValidation', employeeName?: string | null, employeeId?: number | null, messages: Array<string> }> } };

export type GenerateRoeReportQueryVariables = Exact<{
  params: RoeReportParameterInput;
}>;


export type GenerateRoeReportQuery = { __typename?: 'Query', generateRoeReport: { __typename?: 'GenerateReportResult', generated: boolean, link?: string | null, messages: Array<string> } };

export type GetAnonUserContextQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
}>;


export type GetAnonUserContextQuery = { __typename?: 'Query', getAnonUserContext: { __typename?: 'AnonUserContext', provinceCode: string } };

export type GetClaimCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClaimCodesQuery = { __typename?: 'Query', getClaimCodes: Array<{ __typename?: 'ClaimCode', key: string, label: string }> };

export type GetDeductionTypesQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetDeductionTypesQuery = { __typename?: 'Query', getDeductionTypes: Array<{ __typename?: 'DeductionType', id: number, label: string, displayOrder: number, taxTreatment: { __typename?: 'TaxTreatment', code: string, label: string, description?: string | null } }> };

export type GetDeductionsQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetDeductionsQuery = { __typename?: 'Query', getDeductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }> };

export type EmployeeFieldsFragment = {
    __typename?: 'Employee',
    id: number,
    firstName: string,
    lastName?: string | null,
    detailedName: string,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    province?: string | null,
    postalCode?: string | null,
    companyUserId?: string | null,
    eiExempt: boolean,
    cppExempt: boolean,
    federalClaimCode: string,
    provincialClaimCode: string,
    employerEiRatio: string,
    payFrequency: string,
    employmentProvince: string,
    vacationPayPercent: string,
    sin?: string | null,
    deleted: boolean,
    accrueVacation: boolean,
    dentalOfferedCode: number,
    dateOfBirth?: string | null
};

export type GetEmployeeQueryVariables = Exact<{
  employeeId: Scalars['Int']['input'];
  currentPayStubId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type GetEmployeeQuery = {
    __typename?: 'Query',
    getEmployee: {
        __typename?: 'EmployeeWithHasPayStubsStatus',
        hasPayStubs: boolean,
        employee: {
            __typename?: 'Employee',
            id: number,
            firstName: string,
            lastName?: string | null,
            detailedName: string,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            province?: string | null,
            postalCode?: string | null,
            companyUserId?: string | null,
            eiExempt: boolean,
            cppExempt: boolean,
            federalClaimCode: string,
            provincialClaimCode: string,
            employerEiRatio: string,
            payFrequency: string,
            employmentProvince: string,
            vacationPayPercent: string,
            sin?: string | null,
            deleted: boolean,
            accrueVacation: boolean,
            dentalOfferedCode: number,
            dateOfBirth?: string | null
        }
    }
};

export type GetEmployeeMostRecentQueryVariables = Exact<{
  employeeId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetEmployeeMostRecentQuery = {
    __typename?: 'Query',
    getEmployeeMostRecent: {
        __typename?: 'EmployeeWithHasPayStubsStatus',
        hasPayStubs: boolean,
        employee: {
            __typename?: 'Employee',
            id: number,
            firstName: string,
            lastName?: string | null,
            detailedName: string,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            province?: string | null,
            postalCode?: string | null,
            companyUserId?: string | null,
            eiExempt: boolean,
            cppExempt: boolean,
            federalClaimCode: string,
            provincialClaimCode: string,
            employerEiRatio: string,
            payFrequency: string,
            employmentProvince: string,
            vacationPayPercent: string,
            sin?: string | null,
            deleted: boolean,
            accrueVacation: boolean,
            dentalOfferedCode: number,
            dateOfBirth?: string | null
        }
    }
};

export type GetEmployeesQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetEmployeesQuery = {
    __typename?: 'Query',
    getEmployees?: {
        __typename?: 'GetEmployeesResult',
        userId?: number | null,
        employees: Array<{
            __typename?: 'Employee',
            id: number,
            firstName: string,
            lastName?: string | null,
            detailedName: string,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            province?: string | null,
            postalCode?: string | null,
            companyUserId?: string | null,
            eiExempt: boolean,
            cppExempt: boolean,
            federalClaimCode: string,
            provincialClaimCode: string,
            employerEiRatio: string,
            payFrequency: string,
            employmentProvince: string,
            vacationPayPercent: string,
            sin?: string | null,
            deleted: boolean,
            accrueVacation: boolean,
            dentalOfferedCode: number,
            dateOfBirth?: string | null
        }>
    } | null
};

export type GetEmployeesWithStatusQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;


export type GetEmployeesWithStatusQuery = {
    __typename?: 'Query',
    getEmployeesWithStatus: Array<{
        __typename?: 'EmployeeWithStatus',
        id: string,
        employee: {
            __typename?: 'Employee',
            id: number,
            firstName: string,
            lastName?: string | null,
            detailedName: string,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            province?: string | null,
            postalCode?: string | null,
            companyUserId?: string | null,
            eiExempt: boolean,
            cppExempt: boolean,
            federalClaimCode: string,
            provincialClaimCode: string,
            employerEiRatio: string,
            payFrequency: string,
            employmentProvince: string,
            vacationPayPercent: string,
            sin?: string | null,
            deleted: boolean,
            accrueVacation: boolean,
            dentalOfferedCode: number,
            dateOfBirth?: string | null
        },
        recommendedActions?: Array<{
            __typename?: 'RecommendedEmployeeAction',
            description: string,
            employeeId: number
        }> | null
    }>
};

export type GetEmployerQueryVariables = Exact<{
  employerId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetEmployerQuery = { __typename?: 'Query', getEmployer: { __typename?: 'Employer', id: number, name: string, detailedName: string, address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, postalCode?: string | null, logoUrl?: string | null, logoWidth?: string | null, logoHeight?: string | null } };

export type GetEmployersQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type GetEmployersQuery = { __typename?: 'Query', getEmployers: Array<{ __typename?: 'Employer', id: number, name: string, detailedName: string, address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, postalCode?: string | null, logoUrl?: string | null, logoWidth?: string | null, logoHeight?: string | null }> };

export type GetIncomeTypesQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetIncomeTypesQuery = { __typename?: 'Query', incomeTypes: Array<{ __typename?: 'IncomeType', id: number, code?: string | null, label: string, rateLabel: string, unitsLabel: string, displayOrder: number, isBenefit: boolean, t4OtherIncomeTypeCode?: string | null, taxTreatment: { __typename?: 'TaxTreatment', code: string, label: string, description?: string | null } }> };

export type GetNextPayDateQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
}>;


export type GetNextPayDateQuery = { __typename?: 'Query', getNextPayDate: { __typename?: 'NextPayDateResult', nextPayDate?: string | null, message?: string | null } };

export type GetNumberOfAnonymousPayStubsToTransferQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
}>;


export type GetNumberOfAnonymousPayStubsToTransferQuery = { __typename?: 'Query', getNumberOfAnonymousPayStubsToTransfer: { __typename?: 'AnonDataCheckResult', numPayStubsToTransfer: number } };

export type GetPayFrequenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayFrequenciesQuery = { __typename?: 'Query', getPayFrequencies: Array<{ __typename?: 'PayFrequency', key: string, label: string }> };

export type PayStubFieldsFragment = { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null };

export type DeductionsFieldsFragment = { __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number };

export type TotalAmountsFieldsFragment = { __typename?: 'TotalAmounts', totalCurrentIncome: string, totalCurrentBenefits: string, totalCurrentGross: string, totalYtdIncome: string, totalYtdBenefits: string, totalYtdGross: string, totalCurrentDeductions: string, totalYtdDeductions: string, totalCurrentNet: string, totalYtdNet: string, totalCurrentNetExclBenefits: string };

export type GetPayStubQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetPayStubQuery = { __typename?: 'Query', payStub: { __typename?: 'PayStubWithDeductions', finalized: boolean, payDate: string, previousPayStubId?: number | null, payStub: { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null }, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }>, totalAmounts?: { __typename?: 'TotalAmounts', totalCurrentIncome: string, totalCurrentBenefits: string, totalCurrentGross: string, totalYtdIncome: string, totalYtdBenefits: string, totalYtdGross: string, totalCurrentDeductions: string, totalYtdDeductions: string, totalCurrentNet: string, totalYtdNet: string, totalCurrentNetExclBenefits: string } | null } };

export type GetIncomeItemsQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetIncomeItemsQuery = { __typename?: 'Query', getIncomeItems: Array<{ __typename?: 'IncomeItem', id: string, currentPeriodItemId: string, priorPeriodItemId: string, payStubId: number, rate?: string | null, units?: string | null, incomeTypeId: number, amount: string, priorPeriodAmount: string, ytdAmount: string, includeInVacationAccrual: boolean }> };

export type GetPayStubsQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;


export type GetPayStubsQuery = {
    __typename?: 'Query', payStubs: Array<{
        __typename?: 'PayStubWithCalculatedDeductions',
        payStubWithCalculatedIncome: {
            __typename?: 'PayStubWithCalculatedIncome',
            payStubId: string,
            payPeriodStart: string,
            payPeriodEnd: string,
            payDate: string,
            employerCpp?: string | null,
            employerCpp2?: string | null,
            employerEi?: string | null,
            employer: { __typename?: 'Employer', id: number, name: string, detailedName: string },
            employeeAndMostRecent: {
                __typename?: 'EmployeeAndMostRecent',
                employee: {
                    __typename?: 'Employee',
                    id: number,
                    firstName: string,
                    lastName?: string | null,
                    detailedName: string,
                    address1?: string | null,
                    address2?: string | null,
                    city?: string | null,
                    province?: string | null,
                    postalCode?: string | null,
                    companyUserId?: string | null,
                    eiExempt: boolean,
                    cppExempt: boolean,
                    federalClaimCode: string,
                    provincialClaimCode: string,
                    employerEiRatio: string,
                    payFrequency: string,
                    employmentProvince: string,
                    vacationPayPercent: string,
                    sin?: string | null,
                    deleted: boolean,
                    accrueVacation: boolean,
                    dentalOfferedCode: number,
                    dateOfBirth?: string | null
                },
                mostRecentEmployee: {
                    __typename?: 'Employee',
                    id: number,
                    firstName: string,
                    lastName?: string | null,
                    detailedName: string,
                    address1?: string | null,
                    address2?: string | null,
                    city?: string | null,
                    province?: string | null,
                    postalCode?: string | null,
                    companyUserId?: string | null,
                    eiExempt: boolean,
                    cppExempt: boolean,
                    federalClaimCode: string,
                    provincialClaimCode: string,
                    employerEiRatio: string,
                    payFrequency: string,
                    employmentProvince: string,
                    vacationPayPercent: string,
                    sin?: string | null,
                    deleted: boolean,
                    accrueVacation: boolean,
                    dentalOfferedCode: number,
                    dateOfBirth?: string | null
                }
            },
            incomeItems: Array<{
                __typename?: 'CurrentIncomeItem',
                incomeName: string,
                incomeLabel: string,
                amount: string
            }>
        },
        calculatedDeductions: {
            __typename?: 'CalculatedDeductions',
            cpp?: string | null,
            cpp2?: string | null,
            ei?: string | null,
            fedTax?: string | null,
            provTax?: string | null,
            incomeTax?: string | null
        }
    }>
};

export type GetPayStubsForEmployeeQueryVariables = Exact<{
  employeeId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetPayStubsForEmployeeQuery = { __typename?: 'Query', getPayStubsForEmployee: Array<{ __typename?: 'PayStubBasic', id: string, payDate: string, payPeriod: string, finalized: boolean }> };

export type GetPreviousPayStubsForEmployeeQueryVariables = Exact<{
  employeeId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  maxPayDate: Scalars['String']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetPreviousPayStubsForEmployeeQuery = { __typename?: 'Query', getPreviousPayStubsForEmployee: Array<{ __typename?: 'PayStubBasic', id: string, payDate: string, payPeriod: string, finalized: boolean }> };

export type GetProvincesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProvincesQuery = { __typename?: 'Query', getProvinces: Array<{ __typename?: 'Province', key: string, label: string, firstYearVacationPayRate: string }> };

export type GetReportsAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReportsAvailableQuery = { __typename?: 'Query', getReportsAvailable: Array<{ __typename?: 'ReportAvailable', id: string, name: string, description: string, infoLink?: string | null, videoGuideLink?: string | null, externalLink?: string | null, pagePath?: ReportPagePath | null, displayOrder: number, params: Array<{ __typename?: 'ReportParam', id: string, name: string, type: ReportParamType, displayOrder: number, description: string, required: boolean, selectOptions?: Array<{ __typename?: 'SelectOption', defaultSelected: boolean, value: string, label: string, displayOrder?: number | null, description?: string | null }> | null }> }> };

export type GetRoeInitialValuesForEmployeeQueryVariables = Exact<{
  employeeId: Scalars['String']['input'];
}>;


export type GetRoeInitialValuesForEmployeeQuery = { __typename?: 'Query', getRoeInitialValuesForEmployee: { __typename?: 'RoeInitialValuesResult', hasFinalizedStubs: boolean, initialValues?: { __typename?: 'RoeInitialValues', employeeId: string, submitDraft: boolean, payPeriodType: RoePayPeriodType, socialInsuranceNumber?: string | null, employeeFirstName: string, employeeMiddleInitial?: string | null, employeeLastName: string, employeeAddress?: string | null, employeeCity?: string | null, employeeProvinceAndCountry?: string | null, employeePostalCode?: string | null, firstDayWorked?: string | null, lastDayForWhichPaid?: string | null, finalPayPeriodEndDate: string, employeeOccupation?: string | null, totalInsurableHours: number, contactFirstName: string, contactLastName: string, contactAreaCode: string, contactPhone: string, insurableEarnings: { __typename?: 'InsurableEarnings', userMessage?: string | null, payPeriods: Array<{ __typename?: 'PayPeriod', id: string, amount: string, number: number, startDate?: string | null, endDate?: string | null }> }, vacationPay?: { __typename?: 'VacationPay', amount?: string | null, vacationPayCode: VacationPayCode, startDate?: string | null, endDate?: string | null } | null, statHolidayPay?: Array<{ __typename?: 'StatHolidayPay', statHolidayNumber: number, holidayDate: string, amount: string }> | null, otherMonies?: Array<{ __typename?: 'OtherMonies', otherMoniesNumber: number, otherMoniesCode: OtherMoniesCode, startDate?: string | null, endDate?: string | null, amount: string }> | null, specialPayments?: Array<{ __typename?: 'SpecialPayments', specialPaymentsCode: SpecialPaymentsCode, startDate?: string | null, endDate?: string | null, amount?: string | null, specialPaymentsPeriod?: SpecialPaymentsPeriod | null }> | null } | null } };

export type GetSignedInUserQueryVariables = Exact<{
  anonUserId: Scalars['String']['input'];
}>;


export type GetSignedInUserQuery = { __typename?: 'Query', getSignedInUser: { __typename?: 'User', id?: number | null, auth0Id?: string | null, email: string, firstName?: string | null, lastName?: string | null, isAdmin: boolean, createdDate?: string | null, provinceCode?: string | null, mobile?: string | null } };

export type GetSubscribersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscribersQuery = { __typename?: 'Query', getSubscribers: Array<{ __typename?: 'UserActivation', firstEmployer?: string | null, firstEmployee?: string | null, numEmployers?: number | null, numEmployees?: number | null, numPayStubs?: number | null, numPayStubsCompleted?: number | null, numPayStubsFinalized?: number | null, numPayStubsWithYtd?: number | null, subscriptionEmployeeCount?: number | null, subscriptionStatus?: string | null, activationStatus?: string | null, user: { __typename?: 'User', id?: number | null, firstName?: string | null, lastName?: string | null, email: string, mobile?: string | null, createdDate?: string | null, isAdmin: boolean } }> };

export type GetT4OtherIncomeTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetT4OtherIncomeTypesQuery = { __typename?: 'Query', t4OtherIncomeTypes: Array<{ __typename?: 'T4OtherIncomeType', t4OtherIncomeTypeCode: string, label?: string | null, description?: string | null, boxNumber?: number | null }> };

export type GetUserActivationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserActivationsQuery = { __typename?: 'Query', getUserActivations: Array<{ __typename?: 'UserActivation', firstEmployer?: string | null, firstEmployee?: string | null, numEmployers?: number | null, numEmployees?: number | null, numPayStubs?: number | null, numPayStubsCompleted?: number | null, numPayStubsFinalized?: number | null, numPayStubsWithYtd?: number | null, subscriptionEmployeeCount?: number | null, subscriptionStatus?: string | null, activationStatus?: string | null, user: { __typename?: 'User', id?: number | null, firstName?: string | null, lastName?: string | null, email: string, mobile?: string | null, createdDate?: string | null, isAdmin: boolean } }> };

export type GetUserSettingQueryVariables = Exact<{
  userSettingType: UserSettingType;
  defaultValue: Scalars['String']['input'];
}>;


export type GetUserSettingQuery = { __typename?: 'Query', getUserSetting: { __typename?: 'UserSetting', id: number, type: UserSettingType, value: string, daysSinceLastUpdated?: number | null } };

export type GetUsersQueryVariables = Exact<{
  userSearchParams: UserSearchParams;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id?: number | null, firstName?: string | null, lastName?: string | null, email: string, auth0Id?: string | null, mobile?: string | null, createdDate?: string | null, provinceCode?: string | null, isAdmin: boolean }> };

export type VacationSummaryFragmentFragment = { __typename?: 'VacationSummary', id: number, previousPeriodEarned: string, currentPeriodEarned: string, ytdEarned: string, previousPeriodUsedPaid: string, currentPeriodUsedPaid: string, ytdUsedPaid: string, vacationAccrued: string, payStubId: number };

export type GetVacationSummaryQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type GetVacationSummaryQuery = { __typename?: 'Query', getVacationSummary: { __typename?: 'VacationSummary', id: number, previousPeriodEarned: string, currentPeriodEarned: string, ytdEarned: string, previousPeriodUsedPaid: string, currentPeriodUsedPaid: string, ytdUsedPaid: string, vacationAccrued: string, payStubId: number } };

export type IncomeTypeTaxTreatmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IncomeTypeTaxTreatmentsQuery = { __typename?: 'Query', incomeTypeTaxTreatments: Array<{ __typename?: 'TaxTreatment', code: string, label: string, description?: string | null }> };

export type PayStubCalculateNeededQueryVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type PayStubCalculateNeededQuery = { __typename?: 'Query', payStubCalculateNeeded: { __typename?: 'CalculatedNeededResult', hasUserProvidedCoreDeductions?: boolean | null, hasStaleCraCalculatedDeductions?: boolean | null } };

export type ResetPayStubMutationVariables = Exact<{
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type ResetPayStubMutation = { __typename?: 'Mutation', resetPayStub: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type RestoreEmployeeMutationVariables = Exact<{
  employeeId: Scalars['Int']['input'];
}>;


export type RestoreEmployeeMutation = { __typename?: 'Mutation', restoreEmployee: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type SaveDeductionMutationVariables = Exact<{
  input: DeductionItemInput;
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type SaveDeductionMutation = { __typename?: 'Mutation', saveDeduction: { __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, payStubId: number, deductionTypeId: number, amount: string, priorPeriodAmount: string, ytdAmount: string } };

export type SaveDeductionTypeMutationVariables = Exact<{
  input: DeductionTypeInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveDeductionTypeMutation = { __typename?: 'Mutation', saveDeductionType: { __typename?: 'DeductionType', id: number, label: string, displayOrder: number, taxTreatment: { __typename?: 'TaxTreatment', code: string, label: string, description?: string | null } } };

export type SaveCalculatedDeductionsMutationVariables = Exact<{
  deductionItems: Array<DeductionItemInput> | DeductionItemInput;
  payStubId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type SaveCalculatedDeductionsMutation = { __typename?: 'Mutation', saveCalculatedDeductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }> };

export type SaveEmployeeMutationVariables = Exact<{
  employee: EmployeeInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveEmployeeMutation = {
    __typename?: 'Mutation',
    saveEmployee: {
        __typename?: 'SaveEmployeeResult',
        successful: boolean,
        messages: Array<string>,
        employee?: {
            __typename?: 'Employee',
            id: number,
            firstName: string,
            lastName?: string | null,
            detailedName: string,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            province?: string | null,
            postalCode?: string | null,
            companyUserId?: string | null,
            eiExempt: boolean,
            cppExempt: boolean,
            federalClaimCode: string,
            provincialClaimCode: string,
            employerEiRatio: string,
            payFrequency: string,
            employmentProvince: string,
            vacationPayPercent: string,
            sin?: string | null,
            deleted: boolean,
            accrueVacation: boolean,
            dentalOfferedCode: number,
            dateOfBirth?: string | null
        } | null
    }
};

export type SaveEmployeeToPayStubMutationVariables = Exact<{
  employeeId: Scalars['String']['input'];
  anonUserId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SaveEmployeeToPayStubMutation = { __typename?: 'Mutation', saveEmployeeToPayStub: { __typename?: 'PayStubWithDeductions', finalized: boolean, payDate: string, previousPayStubId?: number | null, payStub: { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null }, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }>, totalAmounts?: { __typename?: 'TotalAmounts', totalCurrentIncome: string, totalCurrentBenefits: string, totalCurrentGross: string, totalYtdIncome: string, totalYtdBenefits: string, totalYtdGross: string, totalCurrentDeductions: string, totalYtdDeductions: string, totalCurrentNet: string, totalYtdNet: string, totalCurrentNetExclBenefits: string } | null } };

export type SaveEmployerMutationVariables = Exact<{
  employer: EmployerInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveEmployerMutation = { __typename?: 'Mutation', saveEmployer: { __typename?: 'Employer', id: number, name: string, address1?: string | null, address2?: string | null, city?: string | null, province?: string | null, postalCode?: string | null } };

export type SaveEmployerToPayStubMutationVariables = Exact<{
  employerId: Scalars['Int']['input'];
  anonUserId: Scalars['String']['input'];
  payStubId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SaveEmployerToPayStubMutation = { __typename?: 'Mutation', saveEmployerToPayStub: { __typename?: 'PayStubWithDeductions', payDate: string, payStub: { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null }, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }> } };

export type SaveIncomeItemMutationVariables = Exact<{
  input: IncomeItemInput;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveIncomeItemMutation = { __typename?: 'Mutation', saveIncomeItem: { __typename?: 'SaveIncomeItemResult', successful: boolean, message?: string | null, incomeItem?: { __typename?: 'IncomeItem', id: string, currentPeriodItemId: string, priorPeriodItemId: string, payStubId: number, rate?: string | null, units?: string | null, incomeTypeId: number, amount: string, priorPeriodAmount: string, ytdAmount: string, includeInVacationAccrual: boolean } | null } };

export type SaveIncomeTypeMutationVariables = Exact<{
  input: IncomeTypeInput;
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SaveIncomeTypeMutation = { __typename?: 'Mutation', saveIncomeType: { __typename?: 'IncomeType', id: number, code?: string | null, label: string, rateLabel: string, unitsLabel: string, displayOrder: number, isBenefit: boolean, taxTreatment: { __typename?: 'TaxTreatment', code: string, label: string, description?: string | null } } };

export type SaveLogoToEmployerMutationVariables = Exact<{
  input: SaveLogoInput;
  payStubId?: InputMaybe<Scalars['Int']['input']>;
  applyToExistingPayStubs: Scalars['Boolean']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type SaveLogoToEmployerMutation = { __typename?: 'Mutation', saveLogoToEmployer: { __typename?: 'SaveLogoResult', logoUrl?: string | null, successful: boolean, errorMessage?: string | null, employerId: number } };

export type SavePayDateMutationVariables = Exact<{
  input: SavePayDateInput;
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SavePayDateMutation = { __typename?: 'Mutation', savePayDate: { __typename?: 'SavePayDateResult', successful: boolean, message?: string | null, payStubId: number, payDate: string } };

export type SavePayPeriodMutationVariables = Exact<{
  input: SavePayPeriodInput;
  anonUserId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SavePayPeriodMutation = { __typename?: 'Mutation', savePayPeriod: { __typename?: 'SavePayPeriodResult', successful: boolean, message?: string | null, payStubId: number, payPeriodStart: string, payPeriodEnd: string } };

export type SaveUserProfileMutationVariables = Exact<{
  userProfileInput: UserProfileInput;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveUserProfileMutation = { __typename?: 'Mutation', saveUserProfile: { __typename?: 'SaveUserProfileResult', message?: string | null, successful: boolean, user?: { __typename?: 'User', id?: number | null, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, provinceCode?: string | null, auth0Id?: string | null, createdDate?: string | null, isAdmin: boolean } | null } };

export type SaveUserSettingMutationVariables = Exact<{
  userSettingType: UserSettingType;
  value: Scalars['String']['input'];
}>;


export type SaveUserSettingMutation = { __typename?: 'Mutation', saveUserSetting: { __typename?: 'UserSetting', id: number, type: UserSettingType, value: string } };

export type SaveVacationSummaryMutationVariables = Exact<{
  vacationSummaryInput: VacationSummaryInput;
  anonUserId: Scalars['String']['input'];
}>;


export type SaveVacationSummaryMutation = { __typename?: 'Mutation', saveVacationSummary: { __typename?: 'SaveVacationSummaryResult', successful: boolean, message?: string | null, vacationSummary?: { __typename?: 'VacationSummary', id: number, previousPeriodEarned: string, currentPeriodEarned: string, ytdEarned: string, previousPeriodUsedPaid: string, currentPeriodUsedPaid: string, ytdUsedPaid: string, vacationAccrued: string, payStubId: number } | null } };

export type SendEmailVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type SendEmailVerificationMutation = { __typename?: 'Mutation', sendEmailVerification: { __typename?: 'Result', successful: boolean, message?: string | null } };

export type SendSystemNoticeMutationVariables = Exact<{
  message: Scalars['String']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type SendSystemNoticeMutation = { __typename?: 'Mutation', sendSystemNotice: { __typename?: 'SystemNoticeResult', successful: boolean } };

export type SendTrustPilotInvitationMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;


export type SendTrustPilotInvitationMutation = { __typename?: 'Mutation', sendTrustPilotInvitation: { __typename?: 'TrustPilotInvitationResult', sent: boolean, error?: string | null } };

export type TransferAnonUserDataMutationVariables = Exact<{
  anonUserId: Scalars['String']['input'];
}>;


export type TransferAnonUserDataMutation = { __typename?: 'Mutation', transferAnonUserData: { __typename?: 'TransferResult', successful: boolean, message?: string | null } };

export type UpdatePayStubBasedOnPreviousPayStubMutationVariables = Exact<{
  previousPayStubId: Scalars['Int']['input'];
  payStubId: Scalars['Int']['input'];
  nextPayDate: Scalars['String']['input'];
  anonUserId: Scalars['String']['input'];
}>;


export type UpdatePayStubBasedOnPreviousPayStubMutation = { __typename?: 'Mutation', updatePayStubBasedOnPreviousPayStub: { __typename?: 'PayStubWithDeductions', finalized: boolean, payDate: string, previousPayStubId?: number | null, payStub: { __typename?: 'PayStub', id: number, employerId?: string | null, employeeId?: string | null, employerCpp?: string | null, employerCpp2?: string | null, employerEi?: string | null, periodStart: string, periodEnd: string, logo?: string | null, currentPeriodIncomeId?: string | null, priorPeriodsIncomeId?: string | null }, deductions: Array<{ __typename?: 'Deduction', id: string, currentPeriodId?: number | null, priorPeriodId?: number | null, amount: string, deductionTypeId: number, priorPeriodAmount: string, ytdAmount: string, payStubId: number }>, totalAmounts?: { __typename?: 'TotalAmounts', totalCurrentIncome: string, totalCurrentBenefits: string, totalCurrentGross: string, totalYtdIncome: string, totalYtdBenefits: string, totalYtdGross: string, totalCurrentDeductions: string, totalYtdDeductions: string, totalCurrentNet: string, totalYtdNet: string, totalCurrentNetExclBenefits: string } | null } };

export type UpdateUserAuthIdsMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateUserAuthIdsMutation = { __typename?: 'Mutation', updateUserAuthIds: { __typename?: 'Result', successful: boolean, message?: string | null } };

export const EmployeeFieldsFragmentDoc = gql`
    fragment employeeFields on Employee {
  id
  firstName
  lastName
  detailedName
  address1
  address2
  city
  province
  postalCode
  companyUserId
  eiExempt
  cppExempt
  federalClaimCode
  provincialClaimCode
  employerEiRatio
  payFrequency
  employmentProvince
  vacationPayPercent
  sin
  deleted
  accrueVacation
  dentalOfferedCode
        dateOfBirth
}
    `;
export const PayStubFieldsFragmentDoc = gql`
    fragment payStubFields on PayStub {
  id
  employerId
  employeeId
  employerCpp
  employerCpp2
  employerEi
  periodStart
  periodEnd
  logo
  currentPeriodIncomeId
  priorPeriodsIncomeId
}
    `;
export const DeductionsFieldsFragmentDoc = gql`
    fragment deductionsFields on Deduction {
  id
  currentPeriodId
  priorPeriodId
  amount
  deductionTypeId
  priorPeriodAmount
  ytdAmount
  payStubId
}
    `;
export const TotalAmountsFieldsFragmentDoc = gql`
    fragment totalAmountsFields on TotalAmounts {
  totalCurrentIncome
  totalCurrentBenefits
  totalCurrentGross
  totalYtdIncome
  totalYtdBenefits
  totalYtdGross
  totalCurrentDeductions
  totalYtdDeductions
  totalCurrentNet
  totalYtdNet
  totalCurrentNetExclBenefits
}
    `;
export const VacationSummaryFragmentFragmentDoc = gql`
    fragment vacationSummaryFragment on VacationSummary {
  id
  previousPeriodEarned
  currentPeriodEarned
  ytdEarned
  previousPeriodUsedPaid
  currentPeriodUsedPaid
  ytdUsedPaid
  vacationAccrued
  payStubId
}
    `;
export const CalculateDeductionsDocument = gql`
    mutation calculateDeductions($payStubId: Int!, $anonUserId: String!) {
  calculateDeductions(payStubId: $payStubId, anonUserId: $anonUserId) {
    successful
    message
    deductions {
      ...deductionsFields
    }
  }
}
    ${DeductionsFieldsFragmentDoc}`;
export type CalculateDeductionsMutationFn = Apollo.MutationFunction<CalculateDeductionsMutation, CalculateDeductionsMutationVariables>;

/**
 * __useCalculateDeductionsMutation__
 *
 * To run a mutation, you first call `useCalculateDeductionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateDeductionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateDeductionsMutation, { data, loading, error }] = useCalculateDeductionsMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useCalculateDeductionsMutation(baseOptions?: Apollo.MutationHookOptions<CalculateDeductionsMutation, CalculateDeductionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateDeductionsMutation, CalculateDeductionsMutationVariables>(CalculateDeductionsDocument, options);
      }
export type CalculateDeductionsMutationHookResult = ReturnType<typeof useCalculateDeductionsMutation>;
export type CalculateDeductionsMutationResult = Apollo.MutationResult<CalculateDeductionsMutation>;
export type CalculateDeductionsMutationOptions = Apollo.BaseMutationOptions<CalculateDeductionsMutation, CalculateDeductionsMutationVariables>;
export const CalculateVacationSummaryDocument = gql`
    mutation calculateVacationSummary($payStubId: Int!, $anonUserId: String!) {
  calculateVacationSummary(payStubId: $payStubId, anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type CalculateVacationSummaryMutationFn = Apollo.MutationFunction<CalculateVacationSummaryMutation, CalculateVacationSummaryMutationVariables>;

/**
 * __useCalculateVacationSummaryMutation__
 *
 * To run a mutation, you first call `useCalculateVacationSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateVacationSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateVacationSummaryMutation, { data, loading, error }] = useCalculateVacationSummaryMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useCalculateVacationSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CalculateVacationSummaryMutation, CalculateVacationSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalculateVacationSummaryMutation, CalculateVacationSummaryMutationVariables>(CalculateVacationSummaryDocument, options);
      }
export type CalculateVacationSummaryMutationHookResult = ReturnType<typeof useCalculateVacationSummaryMutation>;
export type CalculateVacationSummaryMutationResult = Apollo.MutationResult<CalculateVacationSummaryMutation>;
export type CalculateVacationSummaryMutationOptions = Apollo.BaseMutationOptions<CalculateVacationSummaryMutation, CalculateVacationSummaryMutationVariables>;
export const CheckPayStubAccessDocument = gql`
    query checkPayStubAccess($payStubId: Int!, $anonUserId: String!) {
  checkPayStubAccess(payStubId: $payStubId, anonUserId: $anonUserId) {
    signedInAccess
    anonymousAccess
    userSignedIn
    userSignedInWithoutAccessButHasEmployees
    payStubHasUser
    payStubExists
  }
}
    `;

/**
 * __useCheckPayStubAccessQuery__
 *
 * To run a query within a React component, call `useCheckPayStubAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPayStubAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPayStubAccessQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useCheckPayStubAccessQuery(baseOptions: Apollo.QueryHookOptions<CheckPayStubAccessQuery, CheckPayStubAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPayStubAccessQuery, CheckPayStubAccessQueryVariables>(CheckPayStubAccessDocument, options);
      }
export function useCheckPayStubAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPayStubAccessQuery, CheckPayStubAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPayStubAccessQuery, CheckPayStubAccessQueryVariables>(CheckPayStubAccessDocument, options);
        }
export type CheckPayStubAccessQueryHookResult = ReturnType<typeof useCheckPayStubAccessQuery>;
export type CheckPayStubAccessLazyQueryHookResult = ReturnType<typeof useCheckPayStubAccessLazyQuery>;
export type CheckPayStubAccessQueryResult = Apollo.QueryResult<CheckPayStubAccessQuery, CheckPayStubAccessQueryVariables>;
export const CreateEmptyPayStubDocument = gql`
    mutation createEmptyPayStub($anonUserId: String!, $userId: Int) {
  createEmptyPayStub(anonUserId: $anonUserId, userId: $userId)
}
    `;
export type CreateEmptyPayStubMutationFn = Apollo.MutationFunction<CreateEmptyPayStubMutation, CreateEmptyPayStubMutationVariables>;

/**
 * __useCreateEmptyPayStubMutation__
 *
 * To run a mutation, you first call `useCreateEmptyPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyPayStubMutation, { data, loading, error }] = useCreateEmptyPayStubMutation({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateEmptyPayStubMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmptyPayStubMutation, CreateEmptyPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmptyPayStubMutation, CreateEmptyPayStubMutationVariables>(CreateEmptyPayStubDocument, options);
      }
export type CreateEmptyPayStubMutationHookResult = ReturnType<typeof useCreateEmptyPayStubMutation>;
export type CreateEmptyPayStubMutationResult = Apollo.MutationResult<CreateEmptyPayStubMutation>;
export type CreateEmptyPayStubMutationOptions = Apollo.BaseMutationOptions<CreateEmptyPayStubMutation, CreateEmptyPayStubMutationVariables>;
export const CreateNextPayStubDocument = gql`
    mutation createNextPayStub($previousPayStubId: Int!, $nextPayDate: String!, $userId: Int) {
  createNextPayStub(
    previousPayStubId: $previousPayStubId
    nextPayDate: $nextPayDate
    userId: $userId
  ) {
    successful
    message
    payStubWithDeductions {
      finalized
      payDate
      payStub {
        ...payStubFields
      }
      deductions {
        ...deductionsFields
      }
      previousPayStubId
      totalAmounts {
        ...totalAmountsFields
      }
    }
  }
}
    ${PayStubFieldsFragmentDoc}
${DeductionsFieldsFragmentDoc}
${TotalAmountsFieldsFragmentDoc}`;
export type CreateNextPayStubMutationFn = Apollo.MutationFunction<CreateNextPayStubMutation, CreateNextPayStubMutationVariables>;

/**
 * __useCreateNextPayStubMutation__
 *
 * To run a mutation, you first call `useCreateNextPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextPayStubMutation, { data, loading, error }] = useCreateNextPayStubMutation({
 *   variables: {
 *      previousPayStubId: // value for 'previousPayStubId'
 *      nextPayDate: // value for 'nextPayDate'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateNextPayStubMutation(baseOptions?: Apollo.MutationHookOptions<CreateNextPayStubMutation, CreateNextPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNextPayStubMutation, CreateNextPayStubMutationVariables>(CreateNextPayStubDocument, options);
      }
export type CreateNextPayStubMutationHookResult = ReturnType<typeof useCreateNextPayStubMutation>;
export type CreateNextPayStubMutationResult = Apollo.MutationResult<CreateNextPayStubMutation>;
export type CreateNextPayStubMutationOptions = Apollo.BaseMutationOptions<CreateNextPayStubMutation, CreateNextPayStubMutationVariables>;
export const DeleteDeductionDocument = gql`
    mutation deleteDeduction($currentPeriodId: Int, $priorPeriodId: Int, $anonUserId: String!) {
  deleteDeduction(
    currentPeriodId: $currentPeriodId
    priorPeriodId: $priorPeriodId
    anonUserId: $anonUserId
  ) {
    successful
    message
  }
}
    `;
export type DeleteDeductionMutationFn = Apollo.MutationFunction<DeleteDeductionMutation, DeleteDeductionMutationVariables>;

/**
 * __useDeleteDeductionMutation__
 *
 * To run a mutation, you first call `useDeleteDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeductionMutation, { data, loading, error }] = useDeleteDeductionMutation({
 *   variables: {
 *      currentPeriodId: // value for 'currentPeriodId'
 *      priorPeriodId: // value for 'priorPeriodId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeleteDeductionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeductionMutation, DeleteDeductionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeductionMutation, DeleteDeductionMutationVariables>(DeleteDeductionDocument, options);
      }
export type DeleteDeductionMutationHookResult = ReturnType<typeof useDeleteDeductionMutation>;
export type DeleteDeductionMutationResult = Apollo.MutationResult<DeleteDeductionMutation>;
export type DeleteDeductionMutationOptions = Apollo.BaseMutationOptions<DeleteDeductionMutation, DeleteDeductionMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation deleteEmployee($employeeId: Int!) {
  deleteEmployee(employeeId: $employeeId) {
    successful
    message
  }
}
    `;
export type DeleteEmployeeMutationFn = Apollo.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, options);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const DeleteIncomeItemDocument = gql`
    mutation deleteIncomeItem($currentPeriodId: String!, $priorPeriodId: String!, $anonUserId: String!) {
  deleteIncomeItem(
    currentPeriodItemId: $currentPeriodId
    priorPeriodItemId: $priorPeriodId
    anonUserId: $anonUserId
  ) {
    successful
    message
  }
}
    `;
export type DeleteIncomeItemMutationFn = Apollo.MutationFunction<DeleteIncomeItemMutation, DeleteIncomeItemMutationVariables>;

/**
 * __useDeleteIncomeItemMutation__
 *
 * To run a mutation, you first call `useDeleteIncomeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncomeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomeItemMutation, { data, loading, error }] = useDeleteIncomeItemMutation({
 *   variables: {
 *      currentPeriodId: // value for 'currentPeriodId'
 *      priorPeriodId: // value for 'priorPeriodId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeleteIncomeItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncomeItemMutation, DeleteIncomeItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncomeItemMutation, DeleteIncomeItemMutationVariables>(DeleteIncomeItemDocument, options);
      }
export type DeleteIncomeItemMutationHookResult = ReturnType<typeof useDeleteIncomeItemMutation>;
export type DeleteIncomeItemMutationResult = Apollo.MutationResult<DeleteIncomeItemMutation>;
export type DeleteIncomeItemMutationOptions = Apollo.BaseMutationOptions<DeleteIncomeItemMutation, DeleteIncomeItemMutationVariables>;
export const DeleteIncomeTypeDocument = gql`
    mutation deleteIncomeType($incomeTypeId: Int!, $anonUserId: String!) {
  deleteIncomeType(incomeTypeId: $incomeTypeId, anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type DeleteIncomeTypeMutationFn = Apollo.MutationFunction<DeleteIncomeTypeMutation, DeleteIncomeTypeMutationVariables>;

/**
 * __useDeleteIncomeTypeMutation__
 *
 * To run a mutation, you first call `useDeleteIncomeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIncomeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomeTypeMutation, { data, loading, error }] = useDeleteIncomeTypeMutation({
 *   variables: {
 *      incomeTypeId: // value for 'incomeTypeId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeleteIncomeTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIncomeTypeMutation, DeleteIncomeTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIncomeTypeMutation, DeleteIncomeTypeMutationVariables>(DeleteIncomeTypeDocument, options);
      }
export type DeleteIncomeTypeMutationHookResult = ReturnType<typeof useDeleteIncomeTypeMutation>;
export type DeleteIncomeTypeMutationResult = Apollo.MutationResult<DeleteIncomeTypeMutation>;
export type DeleteIncomeTypeMutationOptions = Apollo.BaseMutationOptions<DeleteIncomeTypeMutation, DeleteIncomeTypeMutationVariables>;
export const DeleteLogoDocument = gql`
    mutation deleteLogo($employerId: Int!, $payStubId: Int, $applyToExistingPayStubs: Boolean!, $anonUserId: String!) {
  deleteLogo(
    employerId: $employerId
    payStubId: $payStubId
    applyToExistingPayStubs: $applyToExistingPayStubs
    anonUserId: $anonUserId
  ) {
    successful
    message
    employerId
  }
}
    `;
export type DeleteLogoMutationFn = Apollo.MutationFunction<DeleteLogoMutation, DeleteLogoMutationVariables>;

/**
 * __useDeleteLogoMutation__
 *
 * To run a mutation, you first call `useDeleteLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogoMutation, { data, loading, error }] = useDeleteLogoMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      payStubId: // value for 'payStubId'
 *      applyToExistingPayStubs: // value for 'applyToExistingPayStubs'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeleteLogoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogoMutation, DeleteLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLogoMutation, DeleteLogoMutationVariables>(DeleteLogoDocument, options);
      }
export type DeleteLogoMutationHookResult = ReturnType<typeof useDeleteLogoMutation>;
export type DeleteLogoMutationResult = Apollo.MutationResult<DeleteLogoMutation>;
export type DeleteLogoMutationOptions = Apollo.BaseMutationOptions<DeleteLogoMutation, DeleteLogoMutationVariables>;
export const DeletePayStubDocument = gql`
    mutation deletePayStub($payStubId: Int!, $anonUserId: String!) {
  deletePayStub(payStubId: $payStubId, anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type DeletePayStubMutationFn = Apollo.MutationFunction<DeletePayStubMutation, DeletePayStubMutationVariables>;

/**
 * __useDeletePayStubMutation__
 *
 * To run a mutation, you first call `useDeletePayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayStubMutation, { data, loading, error }] = useDeletePayStubMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeletePayStubMutation(baseOptions?: Apollo.MutationHookOptions<DeletePayStubMutation, DeletePayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePayStubMutation, DeletePayStubMutationVariables>(DeletePayStubDocument, options);
      }
export type DeletePayStubMutationHookResult = ReturnType<typeof useDeletePayStubMutation>;
export type DeletePayStubMutationResult = Apollo.MutationResult<DeletePayStubMutation>;
export type DeletePayStubMutationOptions = Apollo.BaseMutationOptions<DeletePayStubMutation, DeletePayStubMutationVariables>;
export const DeleteVacationSummaryDocument = gql`
    mutation deleteVacationSummary($payStubId: Int!, $anonUserId: String!) {
  deleteVacationSummary(payStubId: $payStubId, anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type DeleteVacationSummaryMutationFn = Apollo.MutationFunction<DeleteVacationSummaryMutation, DeleteVacationSummaryMutationVariables>;

/**
 * __useDeleteVacationSummaryMutation__
 *
 * To run a mutation, you first call `useDeleteVacationSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacationSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacationSummaryMutation, { data, loading, error }] = useDeleteVacationSummaryMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useDeleteVacationSummaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVacationSummaryMutation, DeleteVacationSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVacationSummaryMutation, DeleteVacationSummaryMutationVariables>(DeleteVacationSummaryDocument, options);
      }
export type DeleteVacationSummaryMutationHookResult = ReturnType<typeof useDeleteVacationSummaryMutation>;
export type DeleteVacationSummaryMutationResult = Apollo.MutationResult<DeleteVacationSummaryMutation>;
export type DeleteVacationSummaryMutationOptions = Apollo.BaseMutationOptions<DeleteVacationSummaryMutation, DeleteVacationSummaryMutationVariables>;
export const DentalCodesDocument = gql`
    query dentalCodes {
  dentalCodes {
    code
    label
  }
}
    `;

/**
 * __useDentalCodesQuery__
 *
 * To run a query within a React component, call `useDentalCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalCodesQuery(baseOptions?: Apollo.QueryHookOptions<DentalCodesQuery, DentalCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DentalCodesQuery, DentalCodesQueryVariables>(DentalCodesDocument, options);
      }
export function useDentalCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DentalCodesQuery, DentalCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DentalCodesQuery, DentalCodesQueryVariables>(DentalCodesDocument, options);
        }
export type DentalCodesQueryHookResult = ReturnType<typeof useDentalCodesQuery>;
export type DentalCodesLazyQueryHookResult = ReturnType<typeof useDentalCodesLazyQuery>;
export type DentalCodesQueryResult = Apollo.QueryResult<DentalCodesQuery, DentalCodesQueryVariables>;
export const ExecuteAdminActionDocument = gql`
    mutation executeAdminAction($token: String!) {
        executeAdminAction(token: $token) {
            successful
            message
        }
    }
`;
export type ExecuteAdminActionMutationFn = Apollo.MutationFunction<ExecuteAdminActionMutation, ExecuteAdminActionMutationVariables>;

/**
 * __useExecuteAdminActionMutation__
 *
 * To run a mutation, you first call `useExecuteAdminActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteAdminActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeAdminActionMutation, { data, loading, error }] = useExecuteAdminActionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useExecuteAdminActionMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteAdminActionMutation, ExecuteAdminActionMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<ExecuteAdminActionMutation, ExecuteAdminActionMutationVariables>(ExecuteAdminActionDocument, options);
}
export type ExecuteAdminActionMutationHookResult = ReturnType<typeof useExecuteAdminActionMutation>;
export type ExecuteAdminActionMutationResult = Apollo.MutationResult<ExecuteAdminActionMutation>;
export type ExecuteAdminActionMutationOptions = Apollo.BaseMutationOptions<ExecuteAdminActionMutation, ExecuteAdminActionMutationVariables>;
export const FinalizePayStubDocument = gql`
    mutation finalizePayStub($payStubId: Int!, $anonUserId: String!) {
  finalizePayStub(payStubId: $payStubId, anonUserId: $anonUserId) {
    finalized
    paymentRequired
    message
  }
}
    `;
export type FinalizePayStubMutationFn = Apollo.MutationFunction<FinalizePayStubMutation, FinalizePayStubMutationVariables>;

/**
 * __useFinalizePayStubMutation__
 *
 * To run a mutation, you first call `useFinalizePayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizePayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizePayStubMutation, { data, loading, error }] = useFinalizePayStubMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useFinalizePayStubMutation(baseOptions?: Apollo.MutationHookOptions<FinalizePayStubMutation, FinalizePayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizePayStubMutation, FinalizePayStubMutationVariables>(FinalizePayStubDocument, options);
      }
export type FinalizePayStubMutationHookResult = ReturnType<typeof useFinalizePayStubMutation>;
export type FinalizePayStubMutationResult = Apollo.MutationResult<FinalizePayStubMutation>;
export type FinalizePayStubMutationOptions = Apollo.BaseMutationOptions<FinalizePayStubMutation, FinalizePayStubMutationVariables>;
export const GeneratePayStubDocument = gql`
    query generatePayStub($payStubId: Int!, $anonUserId: String!) {
  generatePayStub(payStubId: $payStubId, anonUserId: $anonUserId) {
    generated
    messages
    link
  }
}
    `;

/**
 * __useGeneratePayStubQuery__
 *
 * To run a query within a React component, call `useGeneratePayStubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePayStubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePayStubQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGeneratePayStubQuery(baseOptions: Apollo.QueryHookOptions<GeneratePayStubQuery, GeneratePayStubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeneratePayStubQuery, GeneratePayStubQueryVariables>(GeneratePayStubDocument, options);
      }
export function useGeneratePayStubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeneratePayStubQuery, GeneratePayStubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeneratePayStubQuery, GeneratePayStubQueryVariables>(GeneratePayStubDocument, options);
        }
export type GeneratePayStubQueryHookResult = ReturnType<typeof useGeneratePayStubQuery>;
export type GeneratePayStubLazyQueryHookResult = ReturnType<typeof useGeneratePayStubLazyQuery>;
export type GeneratePayStubQueryResult = Apollo.QueryResult<GeneratePayStubQuery, GeneratePayStubQueryVariables>;
export const GenerateReportDocument = gql`
    query generateReport($params: ReportParamsInput!) {
  generateReport(params: $params) {
    generated
    link
    messages
    userAlert
    validations {
      employeeName
      employeeId
      messages
    }
  }
}
    `;

/**
 * __useGenerateReportQuery__
 *
 * To run a query within a React component, call `useGenerateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGenerateReportQuery(baseOptions: Apollo.QueryHookOptions<GenerateReportQuery, GenerateReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateReportQuery, GenerateReportQueryVariables>(GenerateReportDocument, options);
      }
export function useGenerateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateReportQuery, GenerateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateReportQuery, GenerateReportQueryVariables>(GenerateReportDocument, options);
        }
export type GenerateReportQueryHookResult = ReturnType<typeof useGenerateReportQuery>;
export type GenerateReportLazyQueryHookResult = ReturnType<typeof useGenerateReportLazyQuery>;
export type GenerateReportQueryResult = Apollo.QueryResult<GenerateReportQuery, GenerateReportQueryVariables>;
export const GenerateRoeReportDocument = gql`
    query generateRoeReport($params: RoeReportParameterInput!) {
  generateRoeReport(params: $params) {
    generated
    link
    messages
  }
}
    `;

/**
 * __useGenerateRoeReportQuery__
 *
 * To run a query within a React component, call `useGenerateRoeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateRoeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateRoeReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGenerateRoeReportQuery(baseOptions: Apollo.QueryHookOptions<GenerateRoeReportQuery, GenerateRoeReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateRoeReportQuery, GenerateRoeReportQueryVariables>(GenerateRoeReportDocument, options);
      }
export function useGenerateRoeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateRoeReportQuery, GenerateRoeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateRoeReportQuery, GenerateRoeReportQueryVariables>(GenerateRoeReportDocument, options);
        }
export type GenerateRoeReportQueryHookResult = ReturnType<typeof useGenerateRoeReportQuery>;
export type GenerateRoeReportLazyQueryHookResult = ReturnType<typeof useGenerateRoeReportLazyQuery>;
export type GenerateRoeReportQueryResult = Apollo.QueryResult<GenerateRoeReportQuery, GenerateRoeReportQueryVariables>;
export const GetAnonUserContextDocument = gql`
    query getAnonUserContext($anonUserId: String!) {
  getAnonUserContext(anonUserId: $anonUserId) {
    provinceCode
  }
}
    `;

/**
 * __useGetAnonUserContextQuery__
 *
 * To run a query within a React component, call `useGetAnonUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonUserContextQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetAnonUserContextQuery(baseOptions: Apollo.QueryHookOptions<GetAnonUserContextQuery, GetAnonUserContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnonUserContextQuery, GetAnonUserContextQueryVariables>(GetAnonUserContextDocument, options);
      }
export function useGetAnonUserContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnonUserContextQuery, GetAnonUserContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnonUserContextQuery, GetAnonUserContextQueryVariables>(GetAnonUserContextDocument, options);
        }
export type GetAnonUserContextQueryHookResult = ReturnType<typeof useGetAnonUserContextQuery>;
export type GetAnonUserContextLazyQueryHookResult = ReturnType<typeof useGetAnonUserContextLazyQuery>;
export type GetAnonUserContextQueryResult = Apollo.QueryResult<GetAnonUserContextQuery, GetAnonUserContextQueryVariables>;
export const GetClaimCodesDocument = gql`
    query getClaimCodes {
  getClaimCodes {
    key
    label
  }
}
    `;

/**
 * __useGetClaimCodesQuery__
 *
 * To run a query within a React component, call `useGetClaimCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClaimCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetClaimCodesQuery, GetClaimCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClaimCodesQuery, GetClaimCodesQueryVariables>(GetClaimCodesDocument, options);
      }
export function useGetClaimCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClaimCodesQuery, GetClaimCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClaimCodesQuery, GetClaimCodesQueryVariables>(GetClaimCodesDocument, options);
        }
export type GetClaimCodesQueryHookResult = ReturnType<typeof useGetClaimCodesQuery>;
export type GetClaimCodesLazyQueryHookResult = ReturnType<typeof useGetClaimCodesLazyQuery>;
export type GetClaimCodesQueryResult = Apollo.QueryResult<GetClaimCodesQuery, GetClaimCodesQueryVariables>;
export const GetDeductionTypesDocument = gql`
    query getDeductionTypes($anonUserId: String!, $userId: Int) {
  getDeductionTypes(anonUserId: $anonUserId, userId: $userId) {
    id
    label
    taxTreatment {
      code
      label
      description
    }
    displayOrder
  }
}
    `;

/**
 * __useGetDeductionTypesQuery__
 *
 * To run a query within a React component, call `useGetDeductionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeductionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeductionTypesQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDeductionTypesQuery(baseOptions: Apollo.QueryHookOptions<GetDeductionTypesQuery, GetDeductionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeductionTypesQuery, GetDeductionTypesQueryVariables>(GetDeductionTypesDocument, options);
      }
export function useGetDeductionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeductionTypesQuery, GetDeductionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeductionTypesQuery, GetDeductionTypesQueryVariables>(GetDeductionTypesDocument, options);
        }
export type GetDeductionTypesQueryHookResult = ReturnType<typeof useGetDeductionTypesQuery>;
export type GetDeductionTypesLazyQueryHookResult = ReturnType<typeof useGetDeductionTypesLazyQuery>;
export type GetDeductionTypesQueryResult = Apollo.QueryResult<GetDeductionTypesQuery, GetDeductionTypesQueryVariables>;
export const GetDeductionsDocument = gql`
    query getDeductions($payStubId: Int!, $anonUserId: String!) {
  getDeductions(payStubId: $payStubId, anonUserId: $anonUserId) {
    ...deductionsFields
  }
}
    ${DeductionsFieldsFragmentDoc}`;

/**
 * __useGetDeductionsQuery__
 *
 * To run a query within a React component, call `useGetDeductionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeductionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeductionsQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetDeductionsQuery(baseOptions: Apollo.QueryHookOptions<GetDeductionsQuery, GetDeductionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeductionsQuery, GetDeductionsQueryVariables>(GetDeductionsDocument, options);
      }
export function useGetDeductionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeductionsQuery, GetDeductionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeductionsQuery, GetDeductionsQueryVariables>(GetDeductionsDocument, options);
        }
export type GetDeductionsQueryHookResult = ReturnType<typeof useGetDeductionsQuery>;
export type GetDeductionsLazyQueryHookResult = ReturnType<typeof useGetDeductionsLazyQuery>;
export type GetDeductionsQueryResult = Apollo.QueryResult<GetDeductionsQuery, GetDeductionsQueryVariables>;
export const GetEmployeeDocument = gql`
    query getEmployee($employeeId: Int!, $currentPayStubId: Int, $anonUserId: String!) {
  getEmployee(
    employeeId: $employeeId
    currentPayStubId: $currentPayStubId
    anonUserId: $anonUserId
  ) {
    employee {
      ...employeeFields
    }
    hasPayStubs
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      currentPayStubId: // value for 'currentPayStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetEmployeeMostRecentDocument = gql`
    query getEmployeeMostRecent($employeeId: Int!, $anonUserId: String!) {
  getEmployeeMostRecent(employeeId: $employeeId, anonUserId: $anonUserId) {
    employee {
      ...employeeFields
    }
    hasPayStubs
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useGetEmployeeMostRecentQuery__
 *
 * To run a query within a React component, call `useGetEmployeeMostRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeMostRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeMostRecentQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetEmployeeMostRecentQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeMostRecentQuery, GetEmployeeMostRecentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeMostRecentQuery, GetEmployeeMostRecentQueryVariables>(GetEmployeeMostRecentDocument, options);
      }
export function useGetEmployeeMostRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeMostRecentQuery, GetEmployeeMostRecentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeMostRecentQuery, GetEmployeeMostRecentQueryVariables>(GetEmployeeMostRecentDocument, options);
        }
export type GetEmployeeMostRecentQueryHookResult = ReturnType<typeof useGetEmployeeMostRecentQuery>;
export type GetEmployeeMostRecentLazyQueryHookResult = ReturnType<typeof useGetEmployeeMostRecentLazyQuery>;
export type GetEmployeeMostRecentQueryResult = Apollo.QueryResult<GetEmployeeMostRecentQuery, GetEmployeeMostRecentQueryVariables>;
export const GetEmployeesDocument = gql`
    query getEmployees($anonUserId: String!, $userId: Int) {
  getEmployees(anonUserId: $anonUserId, userId: $userId) {
    employees {
      ...employeeFields
    }
    userId
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const GetEmployeesWithStatusDocument = gql`
    query getEmployeesWithStatus($userId: Int!) {
  getEmployeesWithStatus(userId: $userId) {
    id
    employee {
      ...employeeFields
    }
    recommendedActions {
      description
      employeeId
    }
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useGetEmployeesWithStatusQuery__
 *
 * To run a query within a React component, call `useGetEmployeesWithStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesWithStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesWithStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEmployeesWithStatusQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesWithStatusQuery, GetEmployeesWithStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesWithStatusQuery, GetEmployeesWithStatusQueryVariables>(GetEmployeesWithStatusDocument, options);
      }
export function useGetEmployeesWithStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesWithStatusQuery, GetEmployeesWithStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesWithStatusQuery, GetEmployeesWithStatusQueryVariables>(GetEmployeesWithStatusDocument, options);
        }
export type GetEmployeesWithStatusQueryHookResult = ReturnType<typeof useGetEmployeesWithStatusQuery>;
export type GetEmployeesWithStatusLazyQueryHookResult = ReturnType<typeof useGetEmployeesWithStatusLazyQuery>;
export type GetEmployeesWithStatusQueryResult = Apollo.QueryResult<GetEmployeesWithStatusQuery, GetEmployeesWithStatusQueryVariables>;
export const GetEmployerDocument = gql`
    query getEmployer($employerId: Int!, $anonUserId: String!) {
  getEmployer(employerId: $employerId, anonUserId: $anonUserId) {
    id
    name
    detailedName
    address1
    address2
    city
    province
    postalCode
    logoUrl
    logoWidth
    logoHeight
  }
}
    `;

/**
 * __useGetEmployerQuery__
 *
 * To run a query within a React component, call `useGetEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetEmployerQuery(baseOptions: Apollo.QueryHookOptions<GetEmployerQuery, GetEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployerQuery, GetEmployerQueryVariables>(GetEmployerDocument, options);
      }
export function useGetEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployerQuery, GetEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployerQuery, GetEmployerQueryVariables>(GetEmployerDocument, options);
        }
export type GetEmployerQueryHookResult = ReturnType<typeof useGetEmployerQuery>;
export type GetEmployerLazyQueryHookResult = ReturnType<typeof useGetEmployerLazyQuery>;
export type GetEmployerQueryResult = Apollo.QueryResult<GetEmployerQuery, GetEmployerQueryVariables>;
export const GetEmployersDocument = gql`
    query getEmployers($userId: Int, $anonUserId: String!) {
  getEmployers(userId: $userId, anonUserId: $anonUserId) {
    id
    name
    detailedName
    address1
    address2
    city
    province
    postalCode
    logoUrl
    logoWidth
    logoHeight
  }
}
    `;

/**
 * __useGetEmployersQuery__
 *
 * To run a query within a React component, call `useGetEmployersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetEmployersQuery(baseOptions: Apollo.QueryHookOptions<GetEmployersQuery, GetEmployersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployersQuery, GetEmployersQueryVariables>(GetEmployersDocument, options);
      }
export function useGetEmployersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployersQuery, GetEmployersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployersQuery, GetEmployersQueryVariables>(GetEmployersDocument, options);
        }
export type GetEmployersQueryHookResult = ReturnType<typeof useGetEmployersQuery>;
export type GetEmployersLazyQueryHookResult = ReturnType<typeof useGetEmployersLazyQuery>;
export type GetEmployersQueryResult = Apollo.QueryResult<GetEmployersQuery, GetEmployersQueryVariables>;
export const GetIncomeTypesDocument = gql`
    query getIncomeTypes($anonUserId: String!, $userId: Int) {
  incomeTypes(anonUserId: $anonUserId, userId: $userId) {
    id
    code
    label
    rateLabel
    unitsLabel
    taxTreatment {
      code
      label
      description
    }
    displayOrder
    isBenefit
    t4OtherIncomeTypeCode
  }
}
    `;

/**
 * __useGetIncomeTypesQuery__
 *
 * To run a query within a React component, call `useGetIncomeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomeTypesQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetIncomeTypesQuery(baseOptions: Apollo.QueryHookOptions<GetIncomeTypesQuery, GetIncomeTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomeTypesQuery, GetIncomeTypesQueryVariables>(GetIncomeTypesDocument, options);
      }
export function useGetIncomeTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomeTypesQuery, GetIncomeTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomeTypesQuery, GetIncomeTypesQueryVariables>(GetIncomeTypesDocument, options);
        }
export type GetIncomeTypesQueryHookResult = ReturnType<typeof useGetIncomeTypesQuery>;
export type GetIncomeTypesLazyQueryHookResult = ReturnType<typeof useGetIncomeTypesLazyQuery>;
export type GetIncomeTypesQueryResult = Apollo.QueryResult<GetIncomeTypesQuery, GetIncomeTypesQueryVariables>;
export const GetNextPayDateDocument = gql`
    query getNextPayDate($payStubId: Int!) {
  getNextPayDate(payStubId: $payStubId) {
    nextPayDate
    message
  }
}
    `;

/**
 * __useGetNextPayDateQuery__
 *
 * To run a query within a React component, call `useGetNextPayDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextPayDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextPayDateQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *   },
 * });
 */
export function useGetNextPayDateQuery(baseOptions: Apollo.QueryHookOptions<GetNextPayDateQuery, GetNextPayDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextPayDateQuery, GetNextPayDateQueryVariables>(GetNextPayDateDocument, options);
      }
export function useGetNextPayDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextPayDateQuery, GetNextPayDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextPayDateQuery, GetNextPayDateQueryVariables>(GetNextPayDateDocument, options);
        }
export type GetNextPayDateQueryHookResult = ReturnType<typeof useGetNextPayDateQuery>;
export type GetNextPayDateLazyQueryHookResult = ReturnType<typeof useGetNextPayDateLazyQuery>;
export type GetNextPayDateQueryResult = Apollo.QueryResult<GetNextPayDateQuery, GetNextPayDateQueryVariables>;
export const GetNumberOfAnonymousPayStubsToTransferDocument = gql`
    query getNumberOfAnonymousPayStubsToTransfer($anonUserId: String!) {
  getNumberOfAnonymousPayStubsToTransfer(anonUserId: $anonUserId) {
    numPayStubsToTransfer
  }
}
    `;

/**
 * __useGetNumberOfAnonymousPayStubsToTransferQuery__
 *
 * To run a query within a React component, call `useGetNumberOfAnonymousPayStubsToTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberOfAnonymousPayStubsToTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberOfAnonymousPayStubsToTransferQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetNumberOfAnonymousPayStubsToTransferQuery(baseOptions: Apollo.QueryHookOptions<GetNumberOfAnonymousPayStubsToTransferQuery, GetNumberOfAnonymousPayStubsToTransferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNumberOfAnonymousPayStubsToTransferQuery, GetNumberOfAnonymousPayStubsToTransferQueryVariables>(GetNumberOfAnonymousPayStubsToTransferDocument, options);
      }
export function useGetNumberOfAnonymousPayStubsToTransferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNumberOfAnonymousPayStubsToTransferQuery, GetNumberOfAnonymousPayStubsToTransferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNumberOfAnonymousPayStubsToTransferQuery, GetNumberOfAnonymousPayStubsToTransferQueryVariables>(GetNumberOfAnonymousPayStubsToTransferDocument, options);
        }
export type GetNumberOfAnonymousPayStubsToTransferQueryHookResult = ReturnType<typeof useGetNumberOfAnonymousPayStubsToTransferQuery>;
export type GetNumberOfAnonymousPayStubsToTransferLazyQueryHookResult = ReturnType<typeof useGetNumberOfAnonymousPayStubsToTransferLazyQuery>;
export type GetNumberOfAnonymousPayStubsToTransferQueryResult = Apollo.QueryResult<GetNumberOfAnonymousPayStubsToTransferQuery, GetNumberOfAnonymousPayStubsToTransferQueryVariables>;
export const GetPayFrequenciesDocument = gql`
    query getPayFrequencies {
  getPayFrequencies {
    key
    label
  }
}
    `;

/**
 * __useGetPayFrequenciesQuery__
 *
 * To run a query within a React component, call `useGetPayFrequenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayFrequenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayFrequenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayFrequenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetPayFrequenciesQuery, GetPayFrequenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayFrequenciesQuery, GetPayFrequenciesQueryVariables>(GetPayFrequenciesDocument, options);
      }
export function useGetPayFrequenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayFrequenciesQuery, GetPayFrequenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayFrequenciesQuery, GetPayFrequenciesQueryVariables>(GetPayFrequenciesDocument, options);
        }
export type GetPayFrequenciesQueryHookResult = ReturnType<typeof useGetPayFrequenciesQuery>;
export type GetPayFrequenciesLazyQueryHookResult = ReturnType<typeof useGetPayFrequenciesLazyQuery>;
export type GetPayFrequenciesQueryResult = Apollo.QueryResult<GetPayFrequenciesQuery, GetPayFrequenciesQueryVariables>;
export const GetPayStubDocument = gql`
    query getPayStub($id: Int!, $anonUserId: String!) {
  payStub(id: $id, anonUserId: $anonUserId) {
    finalized
    payDate
    payStub {
      ...payStubFields
    }
    deductions {
      ...deductionsFields
    }
    previousPayStubId
    totalAmounts {
      ...totalAmountsFields
    }
  }
}
    ${PayStubFieldsFragmentDoc}
${DeductionsFieldsFragmentDoc}
${TotalAmountsFieldsFragmentDoc}`;

/**
 * __useGetPayStubQuery__
 *
 * To run a query within a React component, call `useGetPayStubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubQuery({
 *   variables: {
 *      id: // value for 'id'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetPayStubQuery(baseOptions: Apollo.QueryHookOptions<GetPayStubQuery, GetPayStubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayStubQuery, GetPayStubQueryVariables>(GetPayStubDocument, options);
      }
export function useGetPayStubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayStubQuery, GetPayStubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayStubQuery, GetPayStubQueryVariables>(GetPayStubDocument, options);
        }
export type GetPayStubQueryHookResult = ReturnType<typeof useGetPayStubQuery>;
export type GetPayStubLazyQueryHookResult = ReturnType<typeof useGetPayStubLazyQuery>;
export type GetPayStubQueryResult = Apollo.QueryResult<GetPayStubQuery, GetPayStubQueryVariables>;
export const GetIncomeItemsDocument = gql`
    query getIncomeItems($payStubId: Int!, $anonUserId: String!) {
  getIncomeItems(payStubId: $payStubId, anonUserId: $anonUserId) {
    id
    currentPeriodItemId
    priorPeriodItemId
    payStubId
    rate
    units
    incomeTypeId
    amount
    priorPeriodAmount
    ytdAmount
    includeInVacationAccrual
  }
}
    `;

/**
 * __useGetIncomeItemsQuery__
 *
 * To run a query within a React component, call `useGetIncomeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomeItemsQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetIncomeItemsQuery(baseOptions: Apollo.QueryHookOptions<GetIncomeItemsQuery, GetIncomeItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomeItemsQuery, GetIncomeItemsQueryVariables>(GetIncomeItemsDocument, options);
      }
export function useGetIncomeItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomeItemsQuery, GetIncomeItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomeItemsQuery, GetIncomeItemsQueryVariables>(GetIncomeItemsDocument, options);
        }
export type GetIncomeItemsQueryHookResult = ReturnType<typeof useGetIncomeItemsQuery>;
export type GetIncomeItemsLazyQueryHookResult = ReturnType<typeof useGetIncomeItemsLazyQuery>;
export type GetIncomeItemsQueryResult = Apollo.QueryResult<GetIncomeItemsQuery, GetIncomeItemsQueryVariables>;
export const GetPayStubsDocument = gql`
    query getPayStubs($userId: Int!) {
  payStubs(userId: $userId) {
    payStubWithCalculatedIncome {
      payStubId
      employer {
        id
        name
        detailedName
      }
      employeeAndMostRecent {
        employee {
          ...employeeFields
        }
        mostRecentEmployee {
          ...employeeFields
        }
      }
      payPeriodStart
      payPeriodEnd
      payDate
      incomeItems {
        incomeName
        incomeLabel
        amount
      }
      employerCpp
      employerCpp2
      employerEi
    }
    calculatedDeductions {
      cpp
      cpp2
      ei
      fedTax
      provTax
      incomeTax
    }
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useGetPayStubsQuery__
 *
 * To run a query within a React component, call `useGetPayStubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPayStubsQuery(baseOptions: Apollo.QueryHookOptions<GetPayStubsQuery, GetPayStubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayStubsQuery, GetPayStubsQueryVariables>(GetPayStubsDocument, options);
      }
export function useGetPayStubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayStubsQuery, GetPayStubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayStubsQuery, GetPayStubsQueryVariables>(GetPayStubsDocument, options);
        }
export type GetPayStubsQueryHookResult = ReturnType<typeof useGetPayStubsQuery>;
export type GetPayStubsLazyQueryHookResult = ReturnType<typeof useGetPayStubsLazyQuery>;
export type GetPayStubsQueryResult = Apollo.QueryResult<GetPayStubsQuery, GetPayStubsQueryVariables>;
export const GetPayStubsForEmployeeDocument = gql`
    query getPayStubsForEmployee($employeeId: Int!, $anonUserId: String!) {
  getPayStubsForEmployee(employeeId: $employeeId, anonUserId: $anonUserId) {
    id
    payDate
    payPeriod
    finalized
  }
}
    `;

/**
 * __useGetPayStubsForEmployeeQuery__
 *
 * To run a query within a React component, call `useGetPayStubsForEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayStubsForEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayStubsForEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetPayStubsForEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetPayStubsForEmployeeQuery, GetPayStubsForEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayStubsForEmployeeQuery, GetPayStubsForEmployeeQueryVariables>(GetPayStubsForEmployeeDocument, options);
      }
export function useGetPayStubsForEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayStubsForEmployeeQuery, GetPayStubsForEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayStubsForEmployeeQuery, GetPayStubsForEmployeeQueryVariables>(GetPayStubsForEmployeeDocument, options);
        }
export type GetPayStubsForEmployeeQueryHookResult = ReturnType<typeof useGetPayStubsForEmployeeQuery>;
export type GetPayStubsForEmployeeLazyQueryHookResult = ReturnType<typeof useGetPayStubsForEmployeeLazyQuery>;
export type GetPayStubsForEmployeeQueryResult = Apollo.QueryResult<GetPayStubsForEmployeeQuery, GetPayStubsForEmployeeQueryVariables>;
export const GetPreviousPayStubsForEmployeeDocument = gql`
    query getPreviousPayStubsForEmployee($employeeId: String!, $payStubId: Int, $maxPayDate: String!, $anonUserId: String!) {
  getPreviousPayStubsForEmployee(
    employeeId: $employeeId
    payStubId: $payStubId
    maxPayDate: $maxPayDate
    anonUserId: $anonUserId
  ) {
    id
    payDate
    payPeriod
    finalized
  }
}
    `;

/**
 * __useGetPreviousPayStubsForEmployeeQuery__
 *
 * To run a query within a React component, call `useGetPreviousPayStubsForEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousPayStubsForEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousPayStubsForEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      payStubId: // value for 'payStubId'
 *      maxPayDate: // value for 'maxPayDate'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetPreviousPayStubsForEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetPreviousPayStubsForEmployeeQuery, GetPreviousPayStubsForEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviousPayStubsForEmployeeQuery, GetPreviousPayStubsForEmployeeQueryVariables>(GetPreviousPayStubsForEmployeeDocument, options);
      }
export function useGetPreviousPayStubsForEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviousPayStubsForEmployeeQuery, GetPreviousPayStubsForEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviousPayStubsForEmployeeQuery, GetPreviousPayStubsForEmployeeQueryVariables>(GetPreviousPayStubsForEmployeeDocument, options);
        }
export type GetPreviousPayStubsForEmployeeQueryHookResult = ReturnType<typeof useGetPreviousPayStubsForEmployeeQuery>;
export type GetPreviousPayStubsForEmployeeLazyQueryHookResult = ReturnType<typeof useGetPreviousPayStubsForEmployeeLazyQuery>;
export type GetPreviousPayStubsForEmployeeQueryResult = Apollo.QueryResult<GetPreviousPayStubsForEmployeeQuery, GetPreviousPayStubsForEmployeeQueryVariables>;
export const GetProvincesDocument = gql`
    query getProvinces {
  getProvinces {
    key
    label
    firstYearVacationPayRate
  }
}
    `;

/**
 * __useGetProvincesQuery__
 *
 * To run a query within a React component, call `useGetProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvincesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProvincesQuery(baseOptions?: Apollo.QueryHookOptions<GetProvincesQuery, GetProvincesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvincesQuery, GetProvincesQueryVariables>(GetProvincesDocument, options);
      }
export function useGetProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvincesQuery, GetProvincesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvincesQuery, GetProvincesQueryVariables>(GetProvincesDocument, options);
        }
export type GetProvincesQueryHookResult = ReturnType<typeof useGetProvincesQuery>;
export type GetProvincesLazyQueryHookResult = ReturnType<typeof useGetProvincesLazyQuery>;
export type GetProvincesQueryResult = Apollo.QueryResult<GetProvincesQuery, GetProvincesQueryVariables>;
export const GetReportsAvailableDocument = gql`
    query getReportsAvailable {
  getReportsAvailable {
    id
    name
    description
    infoLink
    videoGuideLink
    externalLink
    pagePath
    displayOrder
    params {
      id
      name
      type
      displayOrder
      description
      required
      selectOptions {
        defaultSelected
        value
        label
        displayOrder
        description
      }
    }
  }
}
    `;

/**
 * __useGetReportsAvailableQuery__
 *
 * To run a query within a React component, call `useGetReportsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReportsAvailableQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsAvailableQuery, GetReportsAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsAvailableQuery, GetReportsAvailableQueryVariables>(GetReportsAvailableDocument, options);
      }
export function useGetReportsAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsAvailableQuery, GetReportsAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsAvailableQuery, GetReportsAvailableQueryVariables>(GetReportsAvailableDocument, options);
        }
export type GetReportsAvailableQueryHookResult = ReturnType<typeof useGetReportsAvailableQuery>;
export type GetReportsAvailableLazyQueryHookResult = ReturnType<typeof useGetReportsAvailableLazyQuery>;
export type GetReportsAvailableQueryResult = Apollo.QueryResult<GetReportsAvailableQuery, GetReportsAvailableQueryVariables>;
export const GetRoeInitialValuesForEmployeeDocument = gql`
    query getRoeInitialValuesForEmployee($employeeId: String!) {
  getRoeInitialValuesForEmployee(employeeId: $employeeId) {
    hasFinalizedStubs
    initialValues {
      employeeId
      submitDraft
      payPeriodType
      socialInsuranceNumber
      employeeFirstName
      employeeMiddleInitial
      employeeLastName
      employeeAddress
      employeeCity
      employeeProvinceAndCountry
      employeePostalCode
      firstDayWorked
      lastDayForWhichPaid
      finalPayPeriodEndDate
      employeeOccupation
      totalInsurableHours
      contactFirstName
      contactLastName
      contactAreaCode
      contactPhone
      insurableEarnings {
        userMessage
        payPeriods {
          id
          amount
          number
          startDate
          endDate
        }
      }
      vacationPay {
        amount
        vacationPayCode
        startDate
        endDate
      }
      statHolidayPay {
        statHolidayNumber
        holidayDate
        amount
      }
      otherMonies {
        otherMoniesNumber
        otherMoniesCode
        startDate
        endDate
        amount
      }
      specialPayments {
        specialPaymentsCode
        startDate
        endDate
        amount
        specialPaymentsPeriod
      }
    }
  }
}
    `;

/**
 * __useGetRoeInitialValuesForEmployeeQuery__
 *
 * To run a query within a React component, call `useGetRoeInitialValuesForEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoeInitialValuesForEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoeInitialValuesForEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetRoeInitialValuesForEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetRoeInitialValuesForEmployeeQuery, GetRoeInitialValuesForEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoeInitialValuesForEmployeeQuery, GetRoeInitialValuesForEmployeeQueryVariables>(GetRoeInitialValuesForEmployeeDocument, options);
      }
export function useGetRoeInitialValuesForEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoeInitialValuesForEmployeeQuery, GetRoeInitialValuesForEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoeInitialValuesForEmployeeQuery, GetRoeInitialValuesForEmployeeQueryVariables>(GetRoeInitialValuesForEmployeeDocument, options);
        }
export type GetRoeInitialValuesForEmployeeQueryHookResult = ReturnType<typeof useGetRoeInitialValuesForEmployeeQuery>;
export type GetRoeInitialValuesForEmployeeLazyQueryHookResult = ReturnType<typeof useGetRoeInitialValuesForEmployeeLazyQuery>;
export type GetRoeInitialValuesForEmployeeQueryResult = Apollo.QueryResult<GetRoeInitialValuesForEmployeeQuery, GetRoeInitialValuesForEmployeeQueryVariables>;
export const GetSignedInUserDocument = gql`
    query getSignedInUser($anonUserId: String!) {
  getSignedInUser(anonUserId: $anonUserId) {
    id
    auth0Id
    email
    firstName
    lastName
    isAdmin
    createdDate
    provinceCode
    mobile
  }
}
    `;

/**
 * __useGetSignedInUserQuery__
 *
 * To run a query within a React component, call `useGetSignedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedInUserQuery({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetSignedInUserQuery(baseOptions: Apollo.QueryHookOptions<GetSignedInUserQuery, GetSignedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedInUserQuery, GetSignedInUserQueryVariables>(GetSignedInUserDocument, options);
      }
export function useGetSignedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedInUserQuery, GetSignedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedInUserQuery, GetSignedInUserQueryVariables>(GetSignedInUserDocument, options);
        }
export type GetSignedInUserQueryHookResult = ReturnType<typeof useGetSignedInUserQuery>;
export type GetSignedInUserLazyQueryHookResult = ReturnType<typeof useGetSignedInUserLazyQuery>;
export type GetSignedInUserQueryResult = Apollo.QueryResult<GetSignedInUserQuery, GetSignedInUserQueryVariables>;
export const GetSubscribersDocument = gql`
    query getSubscribers {
  getSubscribers {
    user {
      id
      firstName
      lastName
      email
      mobile
      createdDate
      isAdmin
    }
    firstEmployer
    firstEmployee
    numEmployers
    numEmployees
    numPayStubs
    numPayStubsCompleted
    numPayStubsFinalized
    numPayStubsWithYtd
    subscriptionEmployeeCount
    subscriptionStatus
    activationStatus
  }
}
    `;

/**
 * __useGetSubscribersQuery__
 *
 * To run a query within a React component, call `useGetSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscribersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscribersQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscribersQuery, GetSubscribersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscribersQuery, GetSubscribersQueryVariables>(GetSubscribersDocument, options);
      }
export function useGetSubscribersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscribersQuery, GetSubscribersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscribersQuery, GetSubscribersQueryVariables>(GetSubscribersDocument, options);
        }
export type GetSubscribersQueryHookResult = ReturnType<typeof useGetSubscribersQuery>;
export type GetSubscribersLazyQueryHookResult = ReturnType<typeof useGetSubscribersLazyQuery>;
export type GetSubscribersQueryResult = Apollo.QueryResult<GetSubscribersQuery, GetSubscribersQueryVariables>;
export const GetT4OtherIncomeTypesDocument = gql`
    query getT4OtherIncomeTypes {
  t4OtherIncomeTypes {
    t4OtherIncomeTypeCode
    label
    description
    boxNumber
  }
}
    `;

/**
 * __useGetT4OtherIncomeTypesQuery__
 *
 * To run a query within a React component, call `useGetT4OtherIncomeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetT4OtherIncomeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetT4OtherIncomeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetT4OtherIncomeTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetT4OtherIncomeTypesQuery, GetT4OtherIncomeTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetT4OtherIncomeTypesQuery, GetT4OtherIncomeTypesQueryVariables>(GetT4OtherIncomeTypesDocument, options);
      }
export function useGetT4OtherIncomeTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetT4OtherIncomeTypesQuery, GetT4OtherIncomeTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetT4OtherIncomeTypesQuery, GetT4OtherIncomeTypesQueryVariables>(GetT4OtherIncomeTypesDocument, options);
        }
export type GetT4OtherIncomeTypesQueryHookResult = ReturnType<typeof useGetT4OtherIncomeTypesQuery>;
export type GetT4OtherIncomeTypesLazyQueryHookResult = ReturnType<typeof useGetT4OtherIncomeTypesLazyQuery>;
export type GetT4OtherIncomeTypesQueryResult = Apollo.QueryResult<GetT4OtherIncomeTypesQuery, GetT4OtherIncomeTypesQueryVariables>;
export const GetUserActivationsDocument = gql`
    query getUserActivations {
  getUserActivations {
    user {
      id
      firstName
      lastName
      email
      mobile
      createdDate
      isAdmin
    }
    firstEmployer
    firstEmployee
    numEmployers
    numEmployees
    numPayStubs
    numPayStubsCompleted
    numPayStubsFinalized
    numPayStubsWithYtd
    subscriptionEmployeeCount
    subscriptionStatus
    activationStatus
  }
}
    `;

/**
 * __useGetUserActivationsQuery__
 *
 * To run a query within a React component, call `useGetUserActivationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserActivationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserActivationsQuery, GetUserActivationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserActivationsQuery, GetUserActivationsQueryVariables>(GetUserActivationsDocument, options);
      }
export function useGetUserActivationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserActivationsQuery, GetUserActivationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserActivationsQuery, GetUserActivationsQueryVariables>(GetUserActivationsDocument, options);
        }
export type GetUserActivationsQueryHookResult = ReturnType<typeof useGetUserActivationsQuery>;
export type GetUserActivationsLazyQueryHookResult = ReturnType<typeof useGetUserActivationsLazyQuery>;
export type GetUserActivationsQueryResult = Apollo.QueryResult<GetUserActivationsQuery, GetUserActivationsQueryVariables>;
export const GetUserSettingDocument = gql`
    query getUserSetting($userSettingType: UserSettingType!, $defaultValue: String!) {
  getUserSetting(type: $userSettingType, defaultValue: $defaultValue) {
    id
    type
    value
    daysSinceLastUpdated
  }
}
    `;

/**
 * __useGetUserSettingQuery__
 *
 * To run a query within a React component, call `useGetUserSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingQuery({
 *   variables: {
 *      userSettingType: // value for 'userSettingType'
 *      defaultValue: // value for 'defaultValue'
 *   },
 * });
 */
export function useGetUserSettingQuery(baseOptions: Apollo.QueryHookOptions<GetUserSettingQuery, GetUserSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSettingQuery, GetUserSettingQueryVariables>(GetUserSettingDocument, options);
      }
export function useGetUserSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSettingQuery, GetUserSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSettingQuery, GetUserSettingQueryVariables>(GetUserSettingDocument, options);
        }
export type GetUserSettingQueryHookResult = ReturnType<typeof useGetUserSettingQuery>;
export type GetUserSettingLazyQueryHookResult = ReturnType<typeof useGetUserSettingLazyQuery>;
export type GetUserSettingQueryResult = Apollo.QueryResult<GetUserSettingQuery, GetUserSettingQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($userSearchParams: UserSearchParams!) {
  getUsers(userSearchParams: $userSearchParams) {
    id
    firstName
    lastName
    email
    auth0Id
    mobile
    createdDate
    provinceCode
    isAdmin
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      userSearchParams: // value for 'userSearchParams'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetVacationSummaryDocument = gql`
    query getVacationSummary($payStubId: Int!, $anonUserId: String!) {
  getVacationSummary(payStubId: $payStubId, anonUserId: $anonUserId) {
    ...vacationSummaryFragment
  }
}
    ${VacationSummaryFragmentFragmentDoc}`;

/**
 * __useGetVacationSummaryQuery__
 *
 * To run a query within a React component, call `useGetVacationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacationSummaryQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useGetVacationSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetVacationSummaryQuery, GetVacationSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVacationSummaryQuery, GetVacationSummaryQueryVariables>(GetVacationSummaryDocument, options);
      }
export function useGetVacationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVacationSummaryQuery, GetVacationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVacationSummaryQuery, GetVacationSummaryQueryVariables>(GetVacationSummaryDocument, options);
        }
export type GetVacationSummaryQueryHookResult = ReturnType<typeof useGetVacationSummaryQuery>;
export type GetVacationSummaryLazyQueryHookResult = ReturnType<typeof useGetVacationSummaryLazyQuery>;
export type GetVacationSummaryQueryResult = Apollo.QueryResult<GetVacationSummaryQuery, GetVacationSummaryQueryVariables>;
export const IncomeTypeTaxTreatmentsDocument = gql`
    query incomeTypeTaxTreatments {
  incomeTypeTaxTreatments {
    code
    label
    description
  }
}
    `;

/**
 * __useIncomeTypeTaxTreatmentsQuery__
 *
 * To run a query within a React component, call `useIncomeTypeTaxTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomeTypeTaxTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomeTypeTaxTreatmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncomeTypeTaxTreatmentsQuery(baseOptions?: Apollo.QueryHookOptions<IncomeTypeTaxTreatmentsQuery, IncomeTypeTaxTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomeTypeTaxTreatmentsQuery, IncomeTypeTaxTreatmentsQueryVariables>(IncomeTypeTaxTreatmentsDocument, options);
      }
export function useIncomeTypeTaxTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomeTypeTaxTreatmentsQuery, IncomeTypeTaxTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomeTypeTaxTreatmentsQuery, IncomeTypeTaxTreatmentsQueryVariables>(IncomeTypeTaxTreatmentsDocument, options);
        }
export type IncomeTypeTaxTreatmentsQueryHookResult = ReturnType<typeof useIncomeTypeTaxTreatmentsQuery>;
export type IncomeTypeTaxTreatmentsLazyQueryHookResult = ReturnType<typeof useIncomeTypeTaxTreatmentsLazyQuery>;
export type IncomeTypeTaxTreatmentsQueryResult = Apollo.QueryResult<IncomeTypeTaxTreatmentsQuery, IncomeTypeTaxTreatmentsQueryVariables>;
export const PayStubCalculateNeededDocument = gql`
    query payStubCalculateNeeded($payStubId: Int!, $anonUserId: String!) {
  payStubCalculateNeeded(payStubId: $payStubId, anonUserId: $anonUserId) {
    hasUserProvidedCoreDeductions
    hasStaleCraCalculatedDeductions
  }
}
    `;

/**
 * __usePayStubCalculateNeededQuery__
 *
 * To run a query within a React component, call `usePayStubCalculateNeededQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayStubCalculateNeededQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayStubCalculateNeededQuery({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function usePayStubCalculateNeededQuery(baseOptions: Apollo.QueryHookOptions<PayStubCalculateNeededQuery, PayStubCalculateNeededQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayStubCalculateNeededQuery, PayStubCalculateNeededQueryVariables>(PayStubCalculateNeededDocument, options);
      }
export function usePayStubCalculateNeededLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayStubCalculateNeededQuery, PayStubCalculateNeededQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayStubCalculateNeededQuery, PayStubCalculateNeededQueryVariables>(PayStubCalculateNeededDocument, options);
        }
export type PayStubCalculateNeededQueryHookResult = ReturnType<typeof usePayStubCalculateNeededQuery>;
export type PayStubCalculateNeededLazyQueryHookResult = ReturnType<typeof usePayStubCalculateNeededLazyQuery>;
export type PayStubCalculateNeededQueryResult = Apollo.QueryResult<PayStubCalculateNeededQuery, PayStubCalculateNeededQueryVariables>;
export const ResetPayStubDocument = gql`
    mutation resetPayStub($payStubId: Int!, $anonUserId: String!) {
  resetPayStub(payStubId: $payStubId, anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type ResetPayStubMutationFn = Apollo.MutationFunction<ResetPayStubMutation, ResetPayStubMutationVariables>;

/**
 * __useResetPayStubMutation__
 *
 * To run a mutation, you first call `useResetPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayStubMutation, { data, loading, error }] = useResetPayStubMutation({
 *   variables: {
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useResetPayStubMutation(baseOptions?: Apollo.MutationHookOptions<ResetPayStubMutation, ResetPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPayStubMutation, ResetPayStubMutationVariables>(ResetPayStubDocument, options);
      }
export type ResetPayStubMutationHookResult = ReturnType<typeof useResetPayStubMutation>;
export type ResetPayStubMutationResult = Apollo.MutationResult<ResetPayStubMutation>;
export type ResetPayStubMutationOptions = Apollo.BaseMutationOptions<ResetPayStubMutation, ResetPayStubMutationVariables>;
export const RestoreEmployeeDocument = gql`
    mutation restoreEmployee($employeeId: Int!) {
  restoreEmployee(employeeId: $employeeId) {
    successful
    message
  }
}
    `;
export type RestoreEmployeeMutationFn = Apollo.MutationFunction<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;

/**
 * __useRestoreEmployeeMutation__
 *
 * To run a mutation, you first call `useRestoreEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEmployeeMutation, { data, loading, error }] = useRestoreEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useRestoreEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>(RestoreEmployeeDocument, options);
      }
export type RestoreEmployeeMutationHookResult = ReturnType<typeof useRestoreEmployeeMutation>;
export type RestoreEmployeeMutationResult = Apollo.MutationResult<RestoreEmployeeMutation>;
export type RestoreEmployeeMutationOptions = Apollo.BaseMutationOptions<RestoreEmployeeMutation, RestoreEmployeeMutationVariables>;
export const SaveDeductionDocument = gql`
    mutation saveDeduction($input: DeductionItemInput!, $payStubId: Int!, $anonUserId: String!) {
  saveDeduction(deduction: $input, payStubId: $payStubId, anonUserId: $anonUserId) {
    id
    currentPeriodId
    priorPeriodId
    payStubId
    deductionTypeId
    amount
    priorPeriodAmount
    ytdAmount
  }
}
    `;
export type SaveDeductionMutationFn = Apollo.MutationFunction<SaveDeductionMutation, SaveDeductionMutationVariables>;

/**
 * __useSaveDeductionMutation__
 *
 * To run a mutation, you first call `useSaveDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeductionMutation, { data, loading, error }] = useSaveDeductionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveDeductionMutation(baseOptions?: Apollo.MutationHookOptions<SaveDeductionMutation, SaveDeductionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDeductionMutation, SaveDeductionMutationVariables>(SaveDeductionDocument, options);
      }
export type SaveDeductionMutationHookResult = ReturnType<typeof useSaveDeductionMutation>;
export type SaveDeductionMutationResult = Apollo.MutationResult<SaveDeductionMutation>;
export type SaveDeductionMutationOptions = Apollo.BaseMutationOptions<SaveDeductionMutation, SaveDeductionMutationVariables>;
export const SaveDeductionTypeDocument = gql`
    mutation saveDeductionType($input: DeductionTypeInput!, $payStubId: Int, $anonUserId: String!) {
  saveDeductionType(
    deductionTypeInput: $input
    payStubId: $payStubId
    anonUserId: $anonUserId
  ) {
    id
    label
    taxTreatment {
      code
      label
      description
    }
    displayOrder
  }
}
    `;
export type SaveDeductionTypeMutationFn = Apollo.MutationFunction<SaveDeductionTypeMutation, SaveDeductionTypeMutationVariables>;

/**
 * __useSaveDeductionTypeMutation__
 *
 * To run a mutation, you first call `useSaveDeductionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeductionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeductionTypeMutation, { data, loading, error }] = useSaveDeductionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveDeductionTypeMutation(baseOptions?: Apollo.MutationHookOptions<SaveDeductionTypeMutation, SaveDeductionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDeductionTypeMutation, SaveDeductionTypeMutationVariables>(SaveDeductionTypeDocument, options);
      }
export type SaveDeductionTypeMutationHookResult = ReturnType<typeof useSaveDeductionTypeMutation>;
export type SaveDeductionTypeMutationResult = Apollo.MutationResult<SaveDeductionTypeMutation>;
export type SaveDeductionTypeMutationOptions = Apollo.BaseMutationOptions<SaveDeductionTypeMutation, SaveDeductionTypeMutationVariables>;
export const SaveCalculatedDeductionsDocument = gql`
    mutation saveCalculatedDeductions($deductionItems: [DeductionItemInput!]!, $payStubId: Int!, $anonUserId: String!) {
  saveCalculatedDeductions(
    deductionItems: $deductionItems
    payStubId: $payStubId
    anonUserId: $anonUserId
  ) {
    ...deductionsFields
  }
}
    ${DeductionsFieldsFragmentDoc}`;
export type SaveCalculatedDeductionsMutationFn = Apollo.MutationFunction<SaveCalculatedDeductionsMutation, SaveCalculatedDeductionsMutationVariables>;

/**
 * __useSaveCalculatedDeductionsMutation__
 *
 * To run a mutation, you first call `useSaveCalculatedDeductionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCalculatedDeductionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCalculatedDeductionsMutation, { data, loading, error }] = useSaveCalculatedDeductionsMutation({
 *   variables: {
 *      deductionItems: // value for 'deductionItems'
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveCalculatedDeductionsMutation(baseOptions?: Apollo.MutationHookOptions<SaveCalculatedDeductionsMutation, SaveCalculatedDeductionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCalculatedDeductionsMutation, SaveCalculatedDeductionsMutationVariables>(SaveCalculatedDeductionsDocument, options);
      }
export type SaveCalculatedDeductionsMutationHookResult = ReturnType<typeof useSaveCalculatedDeductionsMutation>;
export type SaveCalculatedDeductionsMutationResult = Apollo.MutationResult<SaveCalculatedDeductionsMutation>;
export type SaveCalculatedDeductionsMutationOptions = Apollo.BaseMutationOptions<SaveCalculatedDeductionsMutation, SaveCalculatedDeductionsMutationVariables>;
export const SaveEmployeeDocument = gql`
    mutation saveEmployee($employee: EmployeeInput!, $payStubId: Int, $anonUserId: String!) {
  saveEmployee(
    employeeInput: $employee
    payStubId: $payStubId
    anonUserId: $anonUserId
  ) {
    successful
    messages
    employee {
      ...employeeFields
    }
  }
}
    ${EmployeeFieldsFragmentDoc}`;
export type SaveEmployeeMutationFn = Apollo.MutationFunction<SaveEmployeeMutation, SaveEmployeeMutationVariables>;

/**
 * __useSaveEmployeeMutation__
 *
 * To run a mutation, you first call `useSaveEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployeeMutation, { data, loading, error }] = useSaveEmployeeMutation({
 *   variables: {
 *      employee: // value for 'employee'
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmployeeMutation, SaveEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmployeeMutation, SaveEmployeeMutationVariables>(SaveEmployeeDocument, options);
      }
export type SaveEmployeeMutationHookResult = ReturnType<typeof useSaveEmployeeMutation>;
export type SaveEmployeeMutationResult = Apollo.MutationResult<SaveEmployeeMutation>;
export type SaveEmployeeMutationOptions = Apollo.BaseMutationOptions<SaveEmployeeMutation, SaveEmployeeMutationVariables>;
export const SaveEmployeeToPayStubDocument = gql`
    mutation saveEmployeeToPayStub($employeeId: String!, $anonUserId: String!, $payStubId: Int, $userId: Int) {
  saveEmployeeToPayStub(
    employeeId: $employeeId
    anonUserId: $anonUserId
    payStubId: $payStubId
    userId: $userId
  ) {
    finalized
    payStub {
      ...payStubFields
    }
    deductions {
      ...deductionsFields
    }
    payDate
    previousPayStubId
    totalAmounts {
      ...totalAmountsFields
    }
  }
}
    ${PayStubFieldsFragmentDoc}
${DeductionsFieldsFragmentDoc}
${TotalAmountsFieldsFragmentDoc}`;
export type SaveEmployeeToPayStubMutationFn = Apollo.MutationFunction<SaveEmployeeToPayStubMutation, SaveEmployeeToPayStubMutationVariables>;

/**
 * __useSaveEmployeeToPayStubMutation__
 *
 * To run a mutation, you first call `useSaveEmployeeToPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployeeToPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployeeToPayStubMutation, { data, loading, error }] = useSaveEmployeeToPayStubMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      anonUserId: // value for 'anonUserId'
 *      payStubId: // value for 'payStubId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSaveEmployeeToPayStubMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmployeeToPayStubMutation, SaveEmployeeToPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmployeeToPayStubMutation, SaveEmployeeToPayStubMutationVariables>(SaveEmployeeToPayStubDocument, options);
      }
export type SaveEmployeeToPayStubMutationHookResult = ReturnType<typeof useSaveEmployeeToPayStubMutation>;
export type SaveEmployeeToPayStubMutationResult = Apollo.MutationResult<SaveEmployeeToPayStubMutation>;
export type SaveEmployeeToPayStubMutationOptions = Apollo.BaseMutationOptions<SaveEmployeeToPayStubMutation, SaveEmployeeToPayStubMutationVariables>;
export const SaveEmployerDocument = gql`
    mutation saveEmployer($employer: EmployerInput!, $payStubId: Int, $anonUserId: String!) {
  saveEmployer(
    employerInput: $employer
    payStubId: $payStubId
    anonUserId: $anonUserId
  ) {
    id
    name
    address1
    address2
    city
    province
    postalCode
  }
}
    `;
export type SaveEmployerMutationFn = Apollo.MutationFunction<SaveEmployerMutation, SaveEmployerMutationVariables>;

/**
 * __useSaveEmployerMutation__
 *
 * To run a mutation, you first call `useSaveEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployerMutation, { data, loading, error }] = useSaveEmployerMutation({
 *   variables: {
 *      employer: // value for 'employer'
 *      payStubId: // value for 'payStubId'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveEmployerMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmployerMutation, SaveEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmployerMutation, SaveEmployerMutationVariables>(SaveEmployerDocument, options);
      }
export type SaveEmployerMutationHookResult = ReturnType<typeof useSaveEmployerMutation>;
export type SaveEmployerMutationResult = Apollo.MutationResult<SaveEmployerMutation>;
export type SaveEmployerMutationOptions = Apollo.BaseMutationOptions<SaveEmployerMutation, SaveEmployerMutationVariables>;
export const SaveEmployerToPayStubDocument = gql`
    mutation saveEmployerToPayStub($employerId: Int!, $anonUserId: String!, $payStubId: Int) {
  saveEmployerToPayStub(
    employerId: $employerId
    anonUserId: $anonUserId
    payStubId: $payStubId
  ) {
    payStub {
      id
      employerId
      employeeId
      employerCpp
      employerCpp2
      employerEi
      periodStart
      periodEnd
      logo
      currentPeriodIncomeId
      priorPeriodsIncomeId
    }
    deductions {
      ...deductionsFields
    }
    payDate
  }
}
    ${DeductionsFieldsFragmentDoc}`;
export type SaveEmployerToPayStubMutationFn = Apollo.MutationFunction<SaveEmployerToPayStubMutation, SaveEmployerToPayStubMutationVariables>;

/**
 * __useSaveEmployerToPayStubMutation__
 *
 * To run a mutation, you first call `useSaveEmployerToPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEmployerToPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEmployerToPayStubMutation, { data, loading, error }] = useSaveEmployerToPayStubMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      anonUserId: // value for 'anonUserId'
 *      payStubId: // value for 'payStubId'
 *   },
 * });
 */
export function useSaveEmployerToPayStubMutation(baseOptions?: Apollo.MutationHookOptions<SaveEmployerToPayStubMutation, SaveEmployerToPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEmployerToPayStubMutation, SaveEmployerToPayStubMutationVariables>(SaveEmployerToPayStubDocument, options);
      }
export type SaveEmployerToPayStubMutationHookResult = ReturnType<typeof useSaveEmployerToPayStubMutation>;
export type SaveEmployerToPayStubMutationResult = Apollo.MutationResult<SaveEmployerToPayStubMutation>;
export type SaveEmployerToPayStubMutationOptions = Apollo.BaseMutationOptions<SaveEmployerToPayStubMutation, SaveEmployerToPayStubMutationVariables>;
export const SaveIncomeItemDocument = gql`
    mutation saveIncomeItem($input: IncomeItemInput!, $anonUserId: String!) {
  saveIncomeItem(incomeItemInput: $input, anonUserId: $anonUserId) {
    successful
    message
    incomeItem {
      id
      currentPeriodItemId
      priorPeriodItemId
      payStubId
      rate
      units
      incomeTypeId
      amount
      priorPeriodAmount
      ytdAmount
      includeInVacationAccrual
    }
  }
}
    `;
export type SaveIncomeItemMutationFn = Apollo.MutationFunction<SaveIncomeItemMutation, SaveIncomeItemMutationVariables>;

/**
 * __useSaveIncomeItemMutation__
 *
 * To run a mutation, you first call `useSaveIncomeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIncomeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIncomeItemMutation, { data, loading, error }] = useSaveIncomeItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveIncomeItemMutation(baseOptions?: Apollo.MutationHookOptions<SaveIncomeItemMutation, SaveIncomeItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveIncomeItemMutation, SaveIncomeItemMutationVariables>(SaveIncomeItemDocument, options);
      }
export type SaveIncomeItemMutationHookResult = ReturnType<typeof useSaveIncomeItemMutation>;
export type SaveIncomeItemMutationResult = Apollo.MutationResult<SaveIncomeItemMutation>;
export type SaveIncomeItemMutationOptions = Apollo.BaseMutationOptions<SaveIncomeItemMutation, SaveIncomeItemMutationVariables>;
export const SaveIncomeTypeDocument = gql`
    mutation saveIncomeType($input: IncomeTypeInput!, $anonUserId: String!, $userId: Int) {
  saveIncomeType(
    incomeTypeInput: $input
    anonUserId: $anonUserId
    userId: $userId
  ) {
    id
    code
    label
    rateLabel
    unitsLabel
    taxTreatment {
      code
      label
      description
    }
    displayOrder
    isBenefit
  }
}
    `;
export type SaveIncomeTypeMutationFn = Apollo.MutationFunction<SaveIncomeTypeMutation, SaveIncomeTypeMutationVariables>;

/**
 * __useSaveIncomeTypeMutation__
 *
 * To run a mutation, you first call `useSaveIncomeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveIncomeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveIncomeTypeMutation, { data, loading, error }] = useSaveIncomeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSaveIncomeTypeMutation(baseOptions?: Apollo.MutationHookOptions<SaveIncomeTypeMutation, SaveIncomeTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveIncomeTypeMutation, SaveIncomeTypeMutationVariables>(SaveIncomeTypeDocument, options);
      }
export type SaveIncomeTypeMutationHookResult = ReturnType<typeof useSaveIncomeTypeMutation>;
export type SaveIncomeTypeMutationResult = Apollo.MutationResult<SaveIncomeTypeMutation>;
export type SaveIncomeTypeMutationOptions = Apollo.BaseMutationOptions<SaveIncomeTypeMutation, SaveIncomeTypeMutationVariables>;
export const SaveLogoToEmployerDocument = gql`
    mutation saveLogoToEmployer($input: SaveLogoInput!, $payStubId: Int, $applyToExistingPayStubs: Boolean!, $anonUserId: String!) {
  saveLogoToEmployer(
    saveLogoInput: $input
    payStubId: $payStubId
    applyToExistingPayStubs: $applyToExistingPayStubs
    anonUserId: $anonUserId
  ) {
    logoUrl
    successful
    errorMessage
    employerId
  }
}
    `;
export type SaveLogoToEmployerMutationFn = Apollo.MutationFunction<SaveLogoToEmployerMutation, SaveLogoToEmployerMutationVariables>;

/**
 * __useSaveLogoToEmployerMutation__
 *
 * To run a mutation, you first call `useSaveLogoToEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLogoToEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLogoToEmployerMutation, { data, loading, error }] = useSaveLogoToEmployerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      payStubId: // value for 'payStubId'
 *      applyToExistingPayStubs: // value for 'applyToExistingPayStubs'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveLogoToEmployerMutation(baseOptions?: Apollo.MutationHookOptions<SaveLogoToEmployerMutation, SaveLogoToEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLogoToEmployerMutation, SaveLogoToEmployerMutationVariables>(SaveLogoToEmployerDocument, options);
      }
export type SaveLogoToEmployerMutationHookResult = ReturnType<typeof useSaveLogoToEmployerMutation>;
export type SaveLogoToEmployerMutationResult = Apollo.MutationResult<SaveLogoToEmployerMutation>;
export type SaveLogoToEmployerMutationOptions = Apollo.BaseMutationOptions<SaveLogoToEmployerMutation, SaveLogoToEmployerMutationVariables>;
export const SavePayDateDocument = gql`
    mutation savePayDate($input: SavePayDateInput!, $anonUserId: String!, $userId: Int) {
  savePayDate(savePayDateInput: $input, anonUserId: $anonUserId, userId: $userId) {
    successful
    message
    payStubId
    payDate
  }
}
    `;
export type SavePayDateMutationFn = Apollo.MutationFunction<SavePayDateMutation, SavePayDateMutationVariables>;

/**
 * __useSavePayDateMutation__
 *
 * To run a mutation, you first call `useSavePayDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePayDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePayDateMutation, { data, loading, error }] = useSavePayDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSavePayDateMutation(baseOptions?: Apollo.MutationHookOptions<SavePayDateMutation, SavePayDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePayDateMutation, SavePayDateMutationVariables>(SavePayDateDocument, options);
      }
export type SavePayDateMutationHookResult = ReturnType<typeof useSavePayDateMutation>;
export type SavePayDateMutationResult = Apollo.MutationResult<SavePayDateMutation>;
export type SavePayDateMutationOptions = Apollo.BaseMutationOptions<SavePayDateMutation, SavePayDateMutationVariables>;
export const SavePayPeriodDocument = gql`
    mutation savePayPeriod($input: SavePayPeriodInput!, $anonUserId: String!, $userId: Int) {
  savePayPeriod(
    savePayPeriodInput: $input
    anonUserId: $anonUserId
    userId: $userId
  ) {
    successful
    message
    payStubId
    payPeriodStart
    payPeriodEnd
  }
}
    `;
export type SavePayPeriodMutationFn = Apollo.MutationFunction<SavePayPeriodMutation, SavePayPeriodMutationVariables>;

/**
 * __useSavePayPeriodMutation__
 *
 * To run a mutation, you first call `useSavePayPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePayPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePayPeriodMutation, { data, loading, error }] = useSavePayPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *      anonUserId: // value for 'anonUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSavePayPeriodMutation(baseOptions?: Apollo.MutationHookOptions<SavePayPeriodMutation, SavePayPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePayPeriodMutation, SavePayPeriodMutationVariables>(SavePayPeriodDocument, options);
      }
export type SavePayPeriodMutationHookResult = ReturnType<typeof useSavePayPeriodMutation>;
export type SavePayPeriodMutationResult = Apollo.MutationResult<SavePayPeriodMutation>;
export type SavePayPeriodMutationOptions = Apollo.BaseMutationOptions<SavePayPeriodMutation, SavePayPeriodMutationVariables>;
export const SaveUserProfileDocument = gql`
    mutation saveUserProfile($userProfileInput: UserProfileInput!, $anonUserId: String!) {
  saveUserProfile(userProfileInput: $userProfileInput, anonUserId: $anonUserId) {
    message
    successful
    user {
      id
      email
      firstName
      lastName
      mobile
      provinceCode
      auth0Id
      createdDate
      isAdmin
    }
  }
}
    `;
export type SaveUserProfileMutationFn = Apollo.MutationFunction<SaveUserProfileMutation, SaveUserProfileMutationVariables>;

/**
 * __useSaveUserProfileMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileMutation, { data, loading, error }] = useSaveUserProfileMutation({
 *   variables: {
 *      userProfileInput: // value for 'userProfileInput'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserProfileMutation, SaveUserProfileMutationVariables>(SaveUserProfileDocument, options);
      }
export type SaveUserProfileMutationHookResult = ReturnType<typeof useSaveUserProfileMutation>;
export type SaveUserProfileMutationResult = Apollo.MutationResult<SaveUserProfileMutation>;
export type SaveUserProfileMutationOptions = Apollo.BaseMutationOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>;
export const SaveUserSettingDocument = gql`
    mutation saveUserSetting($userSettingType: UserSettingType!, $value: String!) {
  saveUserSetting(type: $userSettingType, value: $value) {
    id
    type
    value
  }
}
    `;
export type SaveUserSettingMutationFn = Apollo.MutationFunction<SaveUserSettingMutation, SaveUserSettingMutationVariables>;

/**
 * __useSaveUserSettingMutation__
 *
 * To run a mutation, you first call `useSaveUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserSettingMutation, { data, loading, error }] = useSaveUserSettingMutation({
 *   variables: {
 *      userSettingType: // value for 'userSettingType'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSaveUserSettingMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserSettingMutation, SaveUserSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserSettingMutation, SaveUserSettingMutationVariables>(SaveUserSettingDocument, options);
      }
export type SaveUserSettingMutationHookResult = ReturnType<typeof useSaveUserSettingMutation>;
export type SaveUserSettingMutationResult = Apollo.MutationResult<SaveUserSettingMutation>;
export type SaveUserSettingMutationOptions = Apollo.BaseMutationOptions<SaveUserSettingMutation, SaveUserSettingMutationVariables>;
export const SaveVacationSummaryDocument = gql`
    mutation saveVacationSummary($vacationSummaryInput: VacationSummaryInput!, $anonUserId: String!) {
  saveVacationSummary(
    vacationSummaryInput: $vacationSummaryInput
    anonUserId: $anonUserId
  ) {
    successful
    vacationSummary {
      ...vacationSummaryFragment
    }
    message
  }
}
    ${VacationSummaryFragmentFragmentDoc}`;
export type SaveVacationSummaryMutationFn = Apollo.MutationFunction<SaveVacationSummaryMutation, SaveVacationSummaryMutationVariables>;

/**
 * __useSaveVacationSummaryMutation__
 *
 * To run a mutation, you first call `useSaveVacationSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVacationSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVacationSummaryMutation, { data, loading, error }] = useSaveVacationSummaryMutation({
 *   variables: {
 *      vacationSummaryInput: // value for 'vacationSummaryInput'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSaveVacationSummaryMutation(baseOptions?: Apollo.MutationHookOptions<SaveVacationSummaryMutation, SaveVacationSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVacationSummaryMutation, SaveVacationSummaryMutationVariables>(SaveVacationSummaryDocument, options);
      }
export type SaveVacationSummaryMutationHookResult = ReturnType<typeof useSaveVacationSummaryMutation>;
export type SaveVacationSummaryMutationResult = Apollo.MutationResult<SaveVacationSummaryMutation>;
export type SaveVacationSummaryMutationOptions = Apollo.BaseMutationOptions<SaveVacationSummaryMutation, SaveVacationSummaryMutationVariables>;
export const SendEmailVerificationDocument = gql`
    mutation sendEmailVerification {
  sendEmailVerification {
    successful
    message
  }
}
    `;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>(SendEmailVerificationDocument, options);
      }
export type SendEmailVerificationMutationHookResult = ReturnType<typeof useSendEmailVerificationMutation>;
export type SendEmailVerificationMutationResult = Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export const SendSystemNoticeDocument = gql`
    mutation sendSystemNotice($message: String!, $anonUserId: String!) {
  sendSystemNotice(message: $message, anonUserId: $anonUserId) {
    successful
  }
}
    `;
export type SendSystemNoticeMutationFn = Apollo.MutationFunction<SendSystemNoticeMutation, SendSystemNoticeMutationVariables>;

/**
 * __useSendSystemNoticeMutation__
 *
 * To run a mutation, you first call `useSendSystemNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSystemNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSystemNoticeMutation, { data, loading, error }] = useSendSystemNoticeMutation({
 *   variables: {
 *      message: // value for 'message'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useSendSystemNoticeMutation(baseOptions?: Apollo.MutationHookOptions<SendSystemNoticeMutation, SendSystemNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSystemNoticeMutation, SendSystemNoticeMutationVariables>(SendSystemNoticeDocument, options);
      }
export type SendSystemNoticeMutationHookResult = ReturnType<typeof useSendSystemNoticeMutation>;
export type SendSystemNoticeMutationResult = Apollo.MutationResult<SendSystemNoticeMutation>;
export type SendSystemNoticeMutationOptions = Apollo.BaseMutationOptions<SendSystemNoticeMutation, SendSystemNoticeMutationVariables>;
export const SendTrustPilotInvitationDocument = gql`
    mutation sendTrustPilotInvitation($userEmail: String!) {
  sendTrustPilotInvitation(userEmail: $userEmail) {
    sent
    error
  }
}
    `;
export type SendTrustPilotInvitationMutationFn = Apollo.MutationFunction<SendTrustPilotInvitationMutation, SendTrustPilotInvitationMutationVariables>;

/**
 * __useSendTrustPilotInvitationMutation__
 *
 * To run a mutation, you first call `useSendTrustPilotInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrustPilotInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrustPilotInvitationMutation, { data, loading, error }] = useSendTrustPilotInvitationMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useSendTrustPilotInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendTrustPilotInvitationMutation, SendTrustPilotInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTrustPilotInvitationMutation, SendTrustPilotInvitationMutationVariables>(SendTrustPilotInvitationDocument, options);
      }
export type SendTrustPilotInvitationMutationHookResult = ReturnType<typeof useSendTrustPilotInvitationMutation>;
export type SendTrustPilotInvitationMutationResult = Apollo.MutationResult<SendTrustPilotInvitationMutation>;
export type SendTrustPilotInvitationMutationOptions = Apollo.BaseMutationOptions<SendTrustPilotInvitationMutation, SendTrustPilotInvitationMutationVariables>;
export const TransferAnonUserDataDocument = gql`
    mutation transferAnonUserData($anonUserId: String!) {
  transferAnonUserData(anonUserId: $anonUserId) {
    successful
    message
  }
}
    `;
export type TransferAnonUserDataMutationFn = Apollo.MutationFunction<TransferAnonUserDataMutation, TransferAnonUserDataMutationVariables>;

/**
 * __useTransferAnonUserDataMutation__
 *
 * To run a mutation, you first call `useTransferAnonUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferAnonUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferAnonUserDataMutation, { data, loading, error }] = useTransferAnonUserDataMutation({
 *   variables: {
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useTransferAnonUserDataMutation(baseOptions?: Apollo.MutationHookOptions<TransferAnonUserDataMutation, TransferAnonUserDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferAnonUserDataMutation, TransferAnonUserDataMutationVariables>(TransferAnonUserDataDocument, options);
      }
export type TransferAnonUserDataMutationHookResult = ReturnType<typeof useTransferAnonUserDataMutation>;
export type TransferAnonUserDataMutationResult = Apollo.MutationResult<TransferAnonUserDataMutation>;
export type TransferAnonUserDataMutationOptions = Apollo.BaseMutationOptions<TransferAnonUserDataMutation, TransferAnonUserDataMutationVariables>;
export const UpdatePayStubBasedOnPreviousPayStubDocument = gql`
    mutation updatePayStubBasedOnPreviousPayStub($previousPayStubId: Int!, $payStubId: Int!, $nextPayDate: String!, $anonUserId: String!) {
  updatePayStubBasedOnPreviousPayStub(
    previousPayStubId: $previousPayStubId
    nextPayDate: $nextPayDate
    payStubId: $payStubId
    anonUserId: $anonUserId
  ) {
    finalized
    payDate
    payStub {
      ...payStubFields
    }
    deductions {
      ...deductionsFields
    }
    previousPayStubId
    totalAmounts {
      ...totalAmountsFields
    }
  }
}
    ${PayStubFieldsFragmentDoc}
${DeductionsFieldsFragmentDoc}
${TotalAmountsFieldsFragmentDoc}`;
export type UpdatePayStubBasedOnPreviousPayStubMutationFn = Apollo.MutationFunction<UpdatePayStubBasedOnPreviousPayStubMutation, UpdatePayStubBasedOnPreviousPayStubMutationVariables>;

/**
 * __useUpdatePayStubBasedOnPreviousPayStubMutation__
 *
 * To run a mutation, you first call `useUpdatePayStubBasedOnPreviousPayStubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayStubBasedOnPreviousPayStubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayStubBasedOnPreviousPayStubMutation, { data, loading, error }] = useUpdatePayStubBasedOnPreviousPayStubMutation({
 *   variables: {
 *      previousPayStubId: // value for 'previousPayStubId'
 *      payStubId: // value for 'payStubId'
 *      nextPayDate: // value for 'nextPayDate'
 *      anonUserId: // value for 'anonUserId'
 *   },
 * });
 */
export function useUpdatePayStubBasedOnPreviousPayStubMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayStubBasedOnPreviousPayStubMutation, UpdatePayStubBasedOnPreviousPayStubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayStubBasedOnPreviousPayStubMutation, UpdatePayStubBasedOnPreviousPayStubMutationVariables>(UpdatePayStubBasedOnPreviousPayStubDocument, options);
      }
export type UpdatePayStubBasedOnPreviousPayStubMutationHookResult = ReturnType<typeof useUpdatePayStubBasedOnPreviousPayStubMutation>;
export type UpdatePayStubBasedOnPreviousPayStubMutationResult = Apollo.MutationResult<UpdatePayStubBasedOnPreviousPayStubMutation>;
export type UpdatePayStubBasedOnPreviousPayStubMutationOptions = Apollo.BaseMutationOptions<UpdatePayStubBasedOnPreviousPayStubMutation, UpdatePayStubBasedOnPreviousPayStubMutationVariables>;
export const UpdateUserAuthIdsDocument = gql`
    mutation updateUserAuthIds {
  updateUserAuthIds {
    successful
    message
  }
}
    `;
export type UpdateUserAuthIdsMutationFn = Apollo.MutationFunction<UpdateUserAuthIdsMutation, UpdateUserAuthIdsMutationVariables>;

/**
 * __useUpdateUserAuthIdsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAuthIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAuthIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAuthIdsMutation, { data, loading, error }] = useUpdateUserAuthIdsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateUserAuthIdsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAuthIdsMutation, UpdateUserAuthIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAuthIdsMutation, UpdateUserAuthIdsMutationVariables>(UpdateUserAuthIdsDocument, options);
      }
export type UpdateUserAuthIdsMutationHookResult = ReturnType<typeof useUpdateUserAuthIdsMutation>;
export type UpdateUserAuthIdsMutationResult = Apollo.MutationResult<UpdateUserAuthIdsMutation>;
export type UpdateUserAuthIdsMutationOptions = Apollo.BaseMutationOptions<UpdateUserAuthIdsMutation, UpdateUserAuthIdsMutationVariables>;